import React, { useState, useEffect } from "react";
import { Select, Input, message, Spin, Button, Table, Modal, Tag } from "antd";
import { http } from "../../../../services/http";
import { useTranslation } from "react-i18next";
import ShipProductModalEdit from "../../../../components/WhrManager/shipProductEdit";
import { getFiveDaysAgo, getToday } from "../../../../utils/getDays";
import { CloseOutlined } from "@ant-design/icons";

const ShipProductModal = ({ datas, onClose, visible }) => {
  const { t } = useTranslation();
  const today = getToday();
  const fiveDaysAgo = getFiveDaysAgo();

  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [loading4, setLoading4] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [driverId, setDriverId] = useState("");
  const [manager, setManager] = useState("");
  const [date, setDate] = useState(today);
  const [status, setStatus] = useState("");
  const [salesLoading, setSalesLoading] = useState(false);

  const [loadingData, setLoadingData] = useState({
    ddata: false,
    wdata: false,
    mdata: false,
  });

  const [data, setData] = useState({
    ddata: [],
    value: "",
    wdata: [],
    mdata: [],
  });

  const [salesData, setSalesData] = useState(null);
  const [docLines, setDocLines] = useState([]);

  const { ddata, wdata, mdata } = data;
  const defaultWarehouse = "Angr-sk";

  const sum =
    salesData?.docCurrency === "UZS"
      ? new Intl.NumberFormat("fr-FR").format(salesData?.docTotalFc)
      : new Intl.NumberFormat("fr-FR").format(salesData?.docTotal);

  const [isCommentModalVisible, setIsCommentModalVisible] = useState(false);
  const [selectedComments, setSelectedComments] = useState("");
  const [onHandQuantityData, setOnHandQuantity] = useState(null);

  const fetchOnHandQuantity = async (itemCodes) => {
    try {
      const { data } = await http.get(
        `api/items/ombordagiitems/on-hand-items?itemCodes=${itemCodes}`,
      );
      setOnHandQuantity(data);
    } catch (error) {
      console.log("Error:", error.message);
    }
  };

  const handleSelectClick = () => {
    setSelectedComments(datas.comments);
    setIsCommentModalVisible(true);
  };

  const handleModalOk = () => {
    setIsCommentModalVisible(false);
  };

  const handleModalCancel = () => {
    setIsCommentModalVisible(false);
  };

  const handleWarehouseChange = (value, rowIndex) => {
    setDocLines((prev) => {
      const updatedData = [...prev];
      updatedData[rowIndex] = {
        ...updatedData[rowIndex],
        warehouseCode: value,
      };
      return updatedData;
    });
  };

  useEffect(() => {
    const fetchAllData = async () => {
      setLoadingData({ ddata: true, wdata: true, mdata: true });
      await Promise.all([
        fetchDrivers(),
        fetchWarehouse(),
        fetchResponsibleManagers(),
      ]);
      setLoadingData({ ddata: false, wdata: false, mdata: false });
    };
    fetchAllData();

    if (visible) {
      resetForm();
    }
  }, [visible]);

  useEffect(() => {
    if (datas) {
      // fetchByDocEntry(datas.docEntry);

      const resData = datas;

      console.log(
        "selectedWhs = ",
        resData?.documentLines.reduce((acc, _, index) => {
          acc[index] = defaultWarehouse;
          return acc;
        }, {}),
      );
      setManager(
        resData?.u_Responsible_Person
          ? Number(resData.u_Responsible_Person)
          : "",
      );
      setDriverId(resData.driver ? resData.driver : "");
      const status = resData.u_OrderStatus;
      setStatus(status);
      setSalesData(resData);
    }
    if (datas?.documentLines) {
      const documentLine = datas.documentLines.map((item) => ({
        ...item,
        warehouseCode: defaultWarehouse,
        warehouseName: "ANGAR SKLADI",
      }));
      const itemCodes = datas.documentLines
        .map((item) => item.itemCode)
        .join(",");
      fetchOnHandQuantity(itemCodes);

      console.log("documentLine = ", documentLine);
      setDocLines(documentLine);
    }
  }, [datas]);

  const fetchDrivers = async () => {
    try {
      const { data } = await http.get(
        `api/sales/driver-business-partners?skip=0`,
      );

      setData((prevState) => ({
        ...prevState,
        ddata: data.data,
      }));
    } catch (err) {
      console.log(err);
    }
  };

  const fetchResponsibleManagers = async () => {
    try {
      const { data } = await http.get(
        `api/sales/warehouse-employee-info?skip=0`,
      );

      setData((prevState) => ({
        ...prevState,
        mdata: data.data,
      }));
    } catch (err) {
      console.log(err);
    }
  };

  const fetchWarehouse = async () => {
    try {
      const { data } = await http.get(`api/warehouses`);
      const mappedItems = data
        .filter((item) => item.warehouseCode !== "01")
        .map((item) => ({
          code: item.warehouseCode,
          name: item.warehouseName,
        }));
      setData((prevState) => ({
        ...prevState,
        wdata: mappedItems,
      }));
    } catch (err) {
      console.log(err);
    }
  };

  const editManager = async () => {
    if (manager) {
      setLoading(true);

      try {
        await http.patch(
          `api/sales/responsible-person/${datas.docEntry}?responsiblePerson=${manager}`,
        );
        message.success(t("sucEditedManager"));
      } catch (error) {
        console.log("Error");
        message.error(t("errCreation") + error.message);
        console.log("Error:", error.message);
      } finally {
        setLoading(false);
      }
    } else {
      message.error(t("selectResponsible"));
    }
  };

  const confirmDelivery = async () => {
    setLoading3(true);
    const dataArray = docLines.map((item) => ({
      lineNumber: item.lineNum,
      // itemCode: item.itemCode,
      warehouseCode: item.warehouseCode,
    }));

    console.log(dataArray);

    try {
      await http.patch(`api/sales/confirmed/${datas.docEntry}`, [...dataArray]);
      message.success(t("sucCreated"));

      setTimeout(() => {
        resetForm();
        onClose();
      }, 100);
    } catch (error) {
      console.log("Error");
      message.error(t("errCreation") + error.message);
      console.log("Error:", error.message);
    } finally {
      setLoading3(false);
    }
  };

  const editDriver = async () => {
    if (driverId) {
      setLoading2(true);

      try {
        await http.patch(
          `api/sales/driver/${datas.docEntry}?driver=${driverId}`,
        );
        message.success(t("sucEditedDriver"));
      } catch (error) {
        console.log("Error");
        message.error(t("errCreation") + error.message);
        console.log("Error:", error.message);
      } finally {
        setLoading2(false);
      }
    } else {
      message.error(t("selectDriver"));
    }
  };

  const editStatus = async () => {
    setLoading4(true);

    try {
      await http.patch(`api/sales/assigned/${datas.docEntry}`);
      message.success(t("sucCreated"));

      setTimeout(() => {
        resetForm();
        onClose();
      }, 800);
    } catch (error) {
      console.log("Error");
      message.error(t("errCreation") + error.message);
      console.log("Error:", error.message);
    } finally {
      setLoading4(false);
    }
  };

  const onEdit = async () => {
    setIsModalVisible(true);
  };

  const resetForm = () => {
    setData((prevState) => ({
      ...prevState,
      value: "",
      managerId: "",
    }));
  };

  if (!salesData) return null;

  const columns = [
    {
      title: t("item"),
      dataIndex: "itemDescription",
      key: "itemDescription",
      width: 280,
      render: (text, record, index) => {
        return (
          <span
            className={`${record.lineStatus === "C" ? "text-slate-500" : "text-black"}`}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: t("quantity"),
      dataIndex: "quantity",
      key: "quantity",

      render: (text, record, index) => {
        const formattedText = new Intl.NumberFormat("fr-FR").format(text);
        return (
          <span
            className={`${record.lineStatus === "C" ? "text-slate-500" : "text-black"}`}
          >
            {formattedText}
          </span>
        );
      },
    },
    {
      title: t("onHand"),
      dataIndex: "onHand",
      key: "onHand",

      render: (text, record, index) => {
        console.log("onHandQuantityData", onHandQuantityData);
        console.log("record", record);
        const onHand =
          onHandQuantityData?.find(
            (item) =>
              item.itemCode === record.itemCode &&
              item.whsCode === record.warehouseCode,
          )?.onHand || 0;
        console.log("onHand", onHand);
        const formattedText = new Intl.NumberFormat("fr-FR").format(onHand);
        return (
          <span
            className={`${record.lineStatus === "C" ? "text-slate-500" : "text-black"}`}
          >
            {formattedText}
          </span>
        );
      },
    },
    {
      title: t("unitMsr"),
      dataIndex: "measureUnit",
      key: "measureUnit",
      text: "center",
      render: (text, record, index) => {
        return (
          <span
            className={`${record.lineStatus === "C" ? "text-slate-500" : "text-black"} text-center`}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: t("price"),
      dataIndex: "price",
      key: "price",
      render: (text, record, index) => {
        const formattedText = new Intl.NumberFormat("fr-FR").format(text);
        return (
          <span
            className={`${record.lineStatus === "C" ? "text-slate-500" : "text-black"}`}
          >
            {formattedText} {record.currency}
          </span>
        );
      },
    },
    {
      title: t("inventoryQuantity"),
      dataIndex: "inventoryQuantity",
      key: "inventoryQuantity",
      render: (text, record, index) => {
        return (
          <span
            className={`${record.lineStatus === "C" ? "text-slate-500" : "text-black"}`}
          >
            {`${text} `}
          </span>
        );
      },
    },
    {
      title: t("ugpName"),
      dataIndex: "ugpName",
      key: "ugpName",
      render: (text, record, index) => {
        return (
          <span
            className={`${record.lineStatus === "C" ? "text-slate-500" : "text-black"}`}
          >
            {`${text} `}
          </span>
        );
      },
    },
    {
      title: t("totalMiqdor"),
      dataIndex: "lineTotal",
      key: "lineTotal",
      render: (text, record) => {
        const formatSum =
          record.currency === "UZS"
            ? new Intl.NumberFormat("fr-FR").format(record?.totalFrgn)
            : new Intl.NumberFormat("fr-FR").format(text);
        return (
          <span
            className={`${record.lineStatus === "C" ? "text-slate-500" : "text-black"}`}
          >
            {`${formatSum} ${record.currency}`}
          </span>
        );
      },
    },
    {
      title: t("whs"),
      dataIndex: "warehouseCode",
      key: "warehouseCode",
      render: (text, record, index) => (
        <Select
          value={text}
          onChange={(value) => handleWarehouseChange(value, index)}
          className="flex h-10 w-[200px] items-center justify-center text-center text-black"
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
          disabled={record.lineStatus === "C"}
        >
          {wdata
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((warehouse) => (
              <Select.Option key={warehouse.code} value={warehouse.code}>
                {warehouse.name}
              </Select.Option>
            ))}
        </Select>
      ),
    },
  ];

  const tableData = docLines?.map((item, index) => ({
    key: index,
    itemDescription: item.itemDescription,
    itemCode: item.itemCode,
    quantity: item.quantity,
    measureUnit: item.measureUnit,
    price: item.price,
    inventoryQuantity: item.inventoryQuantity,
    lineTotal: item.lineTotal,
    warehouseCode: item.warehouseCode,
    ugpName: item.ugpName,
    currency: item.currency,
    totalFrgn: item.totalFrgn || 0,
    onHand: item.onHand,
    lineNum: item.lineNum,
  }));

  return (
    <Modal
      title={
        <h1 className="font-nunito text-xl font-extrabold text-[#000000]">
          {t("order")} № {datas.docNum}
        </h1>
      }
      open={visible}
      onCancel={onClose}
      footer={null}
      closeIcon={<CloseOutlined />}
      width="80%"
      style={{ height: "70%" }}
      loading={salesLoading}
      styles={{ height: "calc(100% - 55px)", overflowY: "auto" }}
    >
      <div className="w-full">
        <div id="ship-product-form" className="w-full">
          <div className="mt-10 flex flex-col items-center justify-between gap-4 sm:flex-row">
            <div className="flex w-full flex-col gap-3 sm:w-auto">
              <p className="font-nunito">{t("mijoz")}</p>
              <Input
                type="text"
                value={datas.cardName}
                className="flex h-10 w-full items-center justify-center font-bold text-black sm:w-[250px]"
                style={{ color: "black" }}
                disabled
              />
            </div>

            <div className="flex w-full flex-col gap-3 sm:w-auto">
              <p className="font-nunito">{t("date")}</p>

              <Input
                type="date"
                value={date}
                className="flex w-full items-center justify-center font-bold text-black sm:w-[120px]"
                onChange={(e) => {
                  setDate(e.target.value);
                }}
                max={today}
                min={fiveDaysAgo}
              />
            </div>
          </div>

          <Table
            columns={columns}
            dataSource={tableData}
            pagination={false}
            className="border-1 mt-12 border-black"
          />

          <div className="mt-10 flex items-center justify-between">
            <div className="flex flex-col gap-3">
              <p>{t("comments")}</p>
              <Select
                value={datas.comments}
                className="flex h-10 w-full items-center justify-center font-bold text-black sm:w-[250px]"
                style={{ color: "black" }}
                disabled
                onClick={handleSelectClick}
              />
              <Modal
                title={t("comments")}
                visible={isCommentModalVisible}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
              >
                <p>{selectedComments}</p>
              </Modal>
            </div>
            <div className="flex items-center justify-end gap-5">
              <div className="flex flex-col gap-3">
                <p>{t("docTotalQuantity")}</p>
                <Input
                  type="text"
                  value={`${new Intl.NumberFormat("fr-FR").format(
                    salesData?.documentLines.reduce((total, item) => {
                      return total + item.inventoryQuantity;
                    }, 0),
                  )} кг`}
                  style={{ color: "black" }}
                  className="flex h-10 w-full items-center justify-center border-[1px] border-[#D0D0D0] font-bold text-black active:border-[#D0D0D0] sm:w-[200px]"
                  disabled
                />
              </div>

              <div className="flex flex-col gap-3">
                <p>{t("totalPrice")}</p>
                <Input
                  type="text"
                  value={`${sum} ${salesData.docCur}`}
                  style={{ color: "black" }}
                  className="flex h-10 w-full items-center justify-center border-[1px] border-[#D0D0D0] font-bold text-black active:border-[#D0D0D0] sm:w-[200px]"
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="mt-10 flex flex-col items-center justify-between gap-4 sm:flex-row">
            <div className="flex flex-col gap-3">
              <p>{t("responsiblePerson")}</p>
              <Spin spinning={loadingData.mdata}>
                <Select
                  value={manager}
                  placeholder={t("chooseManager")}
                  onChange={(val) => {
                    setManager(val);
                  }}
                  disabled={salesData?.u_OrderStatus !== "Tasdiqlandi"}
                  showSearch
                  className="flex h-10 w-full items-center justify-center sm:w-[300px]"
                >
                  {mdata
                    .filter((item) => item.firstName !== null)
                    .map((item) => (
                      <Select.Option key={item.value} value={item.employeeId}>
                        {`${item.firstName} ${item.lastName}`}
                      </Select.Option>
                    ))}
                </Select>
              </Spin>
            </div>

            <div className="flex w-full flex-col gap-3 sm:w-auto">
              <p>{t("driver")}</p>
              <Spin spinning={loadingData.ddata}>
                <Select
                  value={driverId}
                  placeholder={t("chooseDriver")}
                  onChange={(val) => {
                    setDriverId(val);
                  }}
                  disabled={salesData?.u_OrderStatus !== "Tasdiqlandi"}
                  showSearch
                  className="flex h-10 w-full items-center justify-center sm:w-[300px]"
                >
                  {datas.cardCode === "Mijoz0265"
                    ? ddata
                        .filter(
                          (item) =>
                            item.cardName !== null &&
                            item.cardCode === "Haydovchi7",
                        )
                        .map((item) => (
                          <Select.Option key={item.value} value={item.cardCode}>
                            {item.cardName}
                          </Select.Option>
                        ))
                    : ddata
                        .filter(
                          (item) =>
                            item.cardName !== null &&
                            item.cardCode !== "Haydovchi7",
                        )
                        .map((item) => (
                          <Select.Option key={item.value} value={item.cardCode}>
                            {item.cardName}
                          </Select.Option>
                        ))}
                </Select>
              </Spin>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-3 sm:mt-10">
          <div className="flex items-center gap-2">
            <span className="font-bold">{t("statusSale")} : </span>

            <Tag className="text-sm" color={"blue"}>
              {status}
            </Tag>
          </div>
          <div className="flex items-center gap-2">
            <span className="font-bold">{t("timeRp")} : </span>

            {salesData?.u_OrderToDeliveryTime && (
              <Tag className="text-sm" color={"blue"}>
                {salesData?.u_OrderToDeliveryTime}
              </Tag>
            )}
          </div>
          <div className="flex items-center gap-2">
            <span className="font-bold">{t("deliveredTime2")} : </span>
            {salesData?.u_OrderDeliveredTime && (
              <Tag className="text-sm" color={"blue"}>
                {salesData?.u_OrderDeliveredTime}
              </Tag>
            )}
          </div>
        </div>

        <div className="mt-10 flex w-full flex-col items-start justify-between gap-4 sm:flex-row">
          <div className="flex w-full flex-col gap-3">
            <Button
              onClick={editManager}
              className="h-12 w-full rounded-3xl bg-[#0A4D68] text-white sm:w-auto"
              disabled={loading || salesData?.u_OrderStatus !== "Tasdiqlandi"}
              loading={loading}
            >
              {t("assignToResponsible")}
            </Button>
          </div>

          <div className="flex w-full flex-col gap-3">
            <Button
              onClick={onEdit}
              className="h-12 w-full rounded-3xl bg-[#0A4D68] text-white sm:w-auto"
            >
              {t("edit")}
            </Button>
          </div>
          <div className="flex w-full flex-col gap-3">
            <Button
              onClick={confirmDelivery}
              disabled={
                loading3 || salesData?.u_OrderStatus !== "BuyurtmaYuklandi"
              }
              loading={loading3}
              className="h-12 w-full rounded-3xl bg-[#0A4D68] text-white sm:w-auto"
            >
              {t("confirmDelivery")}
            </Button>
          </div>
          <div className="flex w-full flex-col gap-3">
            <Button
              onClick={editStatus}
              disabled={
                loading4 || !manager || !driverId
                // salesData?.u_OrderStatus === "BuyurtmaYuklandi"
              }
              loading={loading4}
              className="h-12 w-full rounded-3xl bg-[#0A4D68] text-white sm:w-auto"
            >
              {t("attach")}
            </Button>
          </div>
          <div className="flex w-full flex-col gap-3">
            <Button
              onClick={editDriver}
              disabled={loading2 || salesData?.u_OrderStatus !== "Tasdiqlandi"}
              loading={loading2}
              className="h-12 w-full rounded-3xl bg-[#0A4D68] text-white sm:w-auto"
            >
              {t("assignDriver")}
            </Button>
          </div>
        </div>
        <ShipProductModalEdit
          visible={isModalVisible}
          data={datas}
          onClose={() => setIsModalVisible(false)}
        />
      </div>
    </Modal>
  );
};

export default ShipProductModal;
