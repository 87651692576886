import React, { useState, useCallback, useEffect, useMemo } from "react";
import { Input, DatePicker, Select, Button } from "antd";
import Menubar from "../../../../components/WhrManager/App";
import { http } from "../../../../services/http";
import ShipProductModal from "./shipProduct";
import { SearchOutlined } from "@ant-design/icons";
import debounce from "lodash.debounce";
import Highlighter from "react-highlight-words";
import { useTranslation } from "react-i18next";
import Header from "../../../../components/header";
import { columnConfirmedSales } from "../../../../utils/columns";
import moment from "moment";

const { RangePicker } = DatePicker;

const DepartmentSales = () => {
  const { t } = useTranslation();

  const today = new Date();
  const day = ("0" + today.getDate()).slice(-2);
  const month = ("0" + (today.getMonth() + 1)).slice(-2);
  const year = today.getFullYear();
  const formattedDate = `${year}-${month}-${day}`;

  const [fdata, setFData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [status, setStatus] = useState("");
  const [docNum, setDocNum] = useState("");
  const [cardName, setCardName] = useState("");
  const [slpName, setSlpName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState(formattedDate);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);

  const orderStatus = [
    { label: "Barchasi", value: "" },
    { label: "Tasdiqlandi", value: "Tasdiqlandi" },
    { label: "Mas'ulga biriktirildi", value: "Biriktirildi" },
    { label: "Buyurtma yuklandi", value: "BuyurtmaYuklandi" },
    { label: "Yuklash tasdiqlandi", value: "YuklashTasdiqlandi" },
  ];

  const fetchData = useCallback(
    async (
      page,
      status,
      ScardName,
      SdocNum,
      SslpName,
      SstartDate,
      SendDate,
    ) => {
      try {
        let url = " ";
        setLoading(true);

        url = `api/tasdiqlanganbuyurtmalar?orderStatus=${status}&cardName=${ScardName}&docNum=${SdocNum}&ownerCode=${SslpName}&startDate=${SstartDate}&endDate=${SendDate}&pageToken=${page}`;

        const { data } = await http.get(url);

        const formattedData = data.docs;

        const hasMore = formattedData.length === 10 ? true : false;
        setFData(formattedData);
        setHasMoreData(hasMore);
        setTotal(data.total);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    },
    [cardName, docNum, status, startDate, endDate, slpName],
  );

  useEffect(() => {
    fetchData(
      currentPage,
      status,
      cardName,
      docNum,
      slpName,
      startDate,
      endDate,
    );
  }, [
    fetchData,
    currentPage,
    status,
    cardName,
    docNum,
    slpName,
    startDate,
    endDate,
  ]);

  useEffect(() => {
    fetchResponsibleManagers();
  }, []);

  const handleNextPage = () => {
    if (hasMoreData) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleRowClick = (record) => {
    setSelectedRecord(record);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
    setSelectedRecord(null);
    setCurrentPage(1);
    fetchData(
      currentPage,
      status,
      cardName,
      docNum,
      slpName,
      startDate,
      endDate,
    );
  };

  let searchInput = null;

  const handleDateRangeChange = (dates, confirm, dataIndex) => {
    debouncedSearch(dates, dataIndex, confirm);
  };

  const handleStartDate = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDate = (e) => {
    setEndDate(e.target.value);
  };

  const debouncedSearch = useMemo(
    () =>
      debounce((value, dataIndex, confirm) => {
        if (dataIndex === "cardName") {
          if (value) {
            setCardName(value);
          } else {
            setCardName("");
          }
        } else if (dataIndex === "docNum") {
          if (value) {
            setDocNum(value);
          } else {
            setDocNum("");
          }
        } else if (dataIndex === "slpName") {
          if (value) {
            setSlpName(value.toUpperCase());
          } else {
            setSlpName("");
          }
        } else if (dataIndex === "docDate") {
          if (value) {
            const formattedStartDate = new Date(value[0]).toLocaleString();
            const formattedEndDate = new Date(value[1]).toLocaleString();

            if (formattedStartDate && formattedEndDate) {
              setStartDate(moment(formattedStartDate).format("YYYY-MM-DD"));
              setEndDate(moment(formattedEndDate).format("YYYY-MM-DD"));
            }
          } else {
            setStartDate("");
            setEndDate("");
          }
        } else {
        }
        confirm();
      }, 3000),
    [fetchData, currentPage],
  );

  const getColumnSearchProps = (dataIndex) => {
    if (dataIndex === "docDate") {
      return {
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
          <div style={{ padding: 8 }}>
            <RangePicker
              value={selectedKeys && selectedKeys[0] ? selectedKeys : []}
              onChange={(dates) => {
                setSelectedKeys(dates ? dates : []);
                handleDateRangeChange(dates, confirm, dataIndex);
              }}
              style={{ marginBottom: 8, display: "block" }}
              disabledDate={(current) =>
                current && current > moment().endOf("day")
              }
            />
          </div>
        ),
        filterIcon: (filtered) => (
          <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
        ),
        onFilter: (value, record) => {
          if (Array.isArray(value) && value.length === 2) {
            const [startDate, endDate] = value;
            const recordDate = moment(record[dataIndex]);
            return recordDate.isBetween(startDate, endDate, "[]");
          }
          return true;
        },
        render: (text) => text,
      };
    }
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`${t("search")} ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              handleSearch(e, confirm, dataIndex);
            }}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          ? record[dataIndex]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : "",
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.select(), 100);
        }
      },
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        ) : (
          text
        ),
    };
  };

  const handleSearch = (e, confirm, dataIndex) => {
    const value = e.target.value;
    debouncedSearch(value, dataIndex, confirm);
  };

  const fetchResponsibleManagers = async () => {
    try {
      const { data } = await http.get(
        `api/sales/warehouse-employee-info?skip=0`,
      );

      setData(data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const columns = columnConfirmedSales(
    t,
    getColumnSearchProps,
    false,
    true,
  ).map((col) => {
    if (col.key === "action") {
      return {
        ...col,
        render: (record) => {
          return (
            <Button className="bg-[#0A4D68] text-white">
              {record?.u_OrderStatus}
            </Button>
          );
        },
        filterDropdown: () => (
          <Select
            allowClear
            style={{ width: 200 }}
            placeholder="Select Description"
            value={status}
            onChange={(value) => {
              setCurrentPage(1);
              setStatus(value !== undefined ? value : "");
            }}
            options={orderStatus.map((item) => {
              return {
                value: item.value,
                label: item.label,
              };
            })}
          />
        ),
      };
    }
    if (col.key === "responsiblePerson") {
      return {
        ...col,
        render: (text, record) => {
          const responsible = record.responsiblePerson
            ? data.find((item) => {
                return item.employeeId === Number(record.responsiblePerson);
              })
            : "-";

          return (
            <span>
              {`${responsible?.firstName ? responsible?.firstName : ""} ${responsible?.lastName ? responsible?.lastName : ""} ` ||
                "-"}
            </span>
          );
        },
      };
    }

    return col;
  });

  return (
    <div className="flex w-full">
      <Menubar />
      <Header
        title={"departmentSales"}
        currentPage={currentPage}
        handlePreviousPage={handlePreviousPage}
        handleNextPage={handleNextPage}
        hasMoreData={hasMoreData}
        columns={columns}
        fdata={fdata}
        loading={loading}
        handleRowClick={handleRowClick}
        onStartDateChange={handleStartDate}
        onEndDateChange={handleEndDate}
        startDate={startDate}
        endDate={endDate}
        formattedDate={formattedDate}
        total={total}
        // scrollX={1100}
      />
      {selectedRecord && (
        <ShipProductModal
          visible={modalVisible}
          datas={selectedRecord}
          onClose={closeModal}
        />
      )}
    </div>
  );
};

export default DepartmentSales;
