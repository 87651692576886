import React, { useState, useEffect } from "react";
import { Modal, Input, Table, Tag, message } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { http } from "../../services/http";
import TransportItemsQuantityModal from "./TransportItemsQuantity";

const TransportModal = ({ visible, data, onClose, refetch }) => {
  const { t } = useTranslation();
  const [dataSource, setDataSource] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [docEntry, setDocEntry] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);

  const getSingleData = async (docEntry) => {
    setLoadingData(true);
    try {
      const { data } = await http.get(
        `api/customs/transports?docEntry=${docEntry}&pageSize=100&skip=0`,
      );

      setDataSource(data);
    } catch (error) {
      // message.error(error?.response?.data?.message);
      console.log("Error:", error?.response?.data?.message);
    } finally {
      setLoadingData(false);
    }
  };

  useEffect(() => {
    if (docEntry) {
      getSingleData(docEntry);
    }
  }, [docEntry]);

  useEffect(() => {
    if (visible) {
      setDocEntry(data?.u_TransportID);
    }
  }, [visible, data]);

  if (!data) return null;

  const columns = [
    {
      title: t("transportType"),
      dataIndex: "u_TransportType",
      key: "u_TransportType",
    },
    {
      title: t("transportNumber"),
      dataIndex: "u_TransportNumber",
      key: "u_TransportNumber",
    },
    {
      title: t("quantity"),
      dataIndex: "u_Amount",
      key: "u_Amount",
      render: (text, record) => {
        const formattedText = new Intl.NumberFormat("fr-FR").format(text);
        return `${formattedText} `;
      },
    },
    {
      title: t("leavingAllowed"),
      dataIndex: "u_LeavingAllowed",
      key: "u_LeavingAllowed",
      render: (text, record) => {
        return text === "No" ? (
          <Tag color="red">{t("notAllowed")}</Tag>
        ) : text === "Yes" ? (
          <Tag color="green">{t("allowed")}</Tag>
        ) : (
          text
        );
      },
    },
    {
      title: t("isTransferMade"),
      dataIndex: "u_IsTransferMade",
      key: "u_IsTransferMade",
      render: (text, record) => {
        return text === "No" ? (
          <Tag color="red">{t("notMade")}</Tag>
        ) : text === "Yes" ? (
          <Tag color="green">{t("made")}</Tag>
        ) : (
          text
        );
      },
    },
  ];

  const handleRowClick = (record) => {
    setSelectedRowData(record);
    setIsModalVisible(true);
  };

  const refetchTable = () => {
    getSingleData(docEntry);
  };

  return (
    <Modal
      title={
        <h1 className="font-nunito text-xl font-extrabold text-[#000000]">
          {t("order")} № {data.docNum}
        </h1>
      }
      open={visible}
      onCancel={onClose}
      footer={null}
      closeIcon={<CloseOutlined />}
      width="80%"
      bodyStyle={{ maxHeight: "80vh", overflowY: "auto" }}
    >
      <div className="w-full px-4 sm:px-5">
        <div className="w-full">
          <div className="mt-5 flex items-center justify-between gap-5">
            <div className="flex flex-col gap-3">
              <p>{t("namePur")}</p>
              <Input
                type="text"
                value={data?.cardName}
                style={{ color: "black" }}
                className="flex h-10 w-full items-center justify-center border-[1px] border-[#D0D0D0] active:border-[#D0D0D0] sm:w-[200px]"
                disabled
              />
            </div>

            <div className="flex flex-col gap-3">
              <p>{t("typeOfTransport")}</p>
              <Input
                type="text"
                value={data.u_typeOfTransport || "-"}
                style={{ color: "black" }}
                className="flex h-10 w-full items-center justify-center border-[1px] border-[#D0D0D0] active:border-[#D0D0D0] sm:w-[150px]"
                disabled
              />
            </div>
            <div className="flex flex-col gap-3">
              <p>{t("numberOfTransport")}</p>
              <Input
                type="text"
                value={data.tansportNumber || "-"}
                style={{ color: "black" }}
                className="flex h-10 w-full items-center justify-center border-[1px] border-[#D0D0D0] active:border-[#D0D0D0] sm:w-[150px]"
                disabled
              />
            </div>
            <div className="flex flex-col gap-3">
              <p>{t("typeOfTransport")}</p>
              <Input
                type="text"
                value={data.u_typeOfTransport || "-"}
                style={{ color: "black" }}
                className="flex h-10 w-full items-center justify-center border-[1px] border-[#D0D0D0] active:border-[#D0D0D0] sm:w-[150px]"
                disabled
              />
            </div>
            <div className="flex flex-col gap-3">
              <p>{t("partyNumber")}</p>
              <Input
                type="text"
                value={data.u_PartyNumber || "-"}
                style={{ color: "black" }}
                className="flex h-10 w-full items-center justify-center border-[1px] border-[#D0D0D0] active:border-[#D0D0D0] sm:w-[170px]"
                disabled
              />
            </div>
          </div>
          <Table
            columns={columns}
            loading={loadingData}
            dataSource={dataSource}
            pagination={false}
            className="border-1 mt-4 w-full border-black sm:mt-12"
            scroll={{ x: "100%" }}
            onRow={(record) => ({
              onClick: () => handleRowClick(record),
            })}
          />
        </div>
      </div>
      <TransportItemsQuantityModal
        visible={isModalVisible}
        data={selectedRowData}
        customDocEntry={data?.docEntry}
        transportDocEntry={data?.u_TransportID}
        partyNumber={data?.u_PartyNumber}
        numberOfTransport={data?.u_NumberOfTransport}
        onClose={() => setIsModalVisible(false)}
        refetch={refetchTable}
      />
    </Modal>
  );
};

export default TransportModal;
