import React from "react";
import { Modal, Button } from "antd";
import { useTranslation } from "react-i18next";

export default function ConfirmExitModal({
  visible,
  handleCloseModal,
  handleClickNo,
}) {
  const { t } = useTranslation();

  return (
    <Modal open={visible} onCancel={handleCloseModal} footer={null}>
      <div className="m-5 flex flex-col items-center justify-between">
        <p className="text-center text-lg font-bold">{t("sureExit")}</p>

        <div className="mt-5 flex items-center gap-5">
          <Button
            className="bg-red-600 text-white"
            key="no"
            onClick={handleClickNo}
          >
            {t("no")}
          </Button>

          <Button
            key="yes"
            className="bg-[#0A4D68] text-white"
            onClick={handleCloseModal}
          >
            {t("yes")}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
