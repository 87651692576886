export function formatNumberWithSpaces(input, decimalSeparator = ".") {
  if (typeof input !== "string" && typeof input !== "number") {
    throw new Error("Input must be a string or number.");
  }

  // Convert input to a string and sanitize it
  let numStr = input
    .toString()
    .replace(/[^0-9.,]/g, "") // Remove non-numeric characters except . and ,
    .replace(/\s+/g, ""); // Remove all spaces

  // Split into integer and decimal parts
  let [integerPart, decimalPart] = numStr.split(/[.,]/);

  // Add spaces as thousand separators to the integer part
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  // console.log('integerPart', integerPart)
  // Return the formatted number
  return decimalPart
    ? `${integerPart}${decimalSeparator}${decimalPart}`
    : integerPart;
}

export function removeSpaces(value) {
  return value.replace(/[^0-9.,]/g, "").replace(/\s+/g, "");
}

export function numberWithSpacesIntl(number) {
  return new Intl.NumberFormat("fr-FR").format(number);
}
