import React, { useState, useMemo, useEffect, useCallback } from "react";
import { Input, DatePicker, Table } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Menubar from "../../../../components/SalesManager/App";
import { http } from "../../../../services/http";
import CreateSaleModal from "../../../../components/SalesManager/createSaleModal";
import RowDataSales from "../../../../components/SalesManager/row-data-shipment-Sales";
import debounce from "lodash.debounce";
import Highlighter from "react-highlight-words";
import { useTranslation } from "react-i18next";
import Header from "../../../../components/header";
import { column, columnAcceptPaymentCustomer } from "../../../../utils/columns";
import { aggregateDocuments } from "../../../../utils/document";
import moment from "moment";
import { get } from "lodash";
import CreatePaymentCustomer from "../../../../components/SalesManager/incoming-payments/create-payment-customer-modal";
import CreatePaymentSupplierModal from "../../../../components/SalesManager/outgoing-payments/create-payment-supplier-modal";
import CreatePaymentAccount from "../../../../components/SalesManager/outgoing-payments/create-payment-accounts-modal";
import { useLocationParams } from "../../../../hooks/use-location-params";
const { RangePicker } = DatePicker;

const today = new Date();
const day = ("0" + today.getDate()).slice(-2);
const month = ("0" + (today.getMonth() + 1)).slice(-2);
const year = today.getFullYear();
const formattedDate = `${year}-${month}-${day}`;

const Xarajatlar = () => {
  const [fdata, setFData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const { query } = useLocationParams();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [cardName, setCardName] = useState("");
  const [slpName, setSlpName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState(formattedDate);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { t } = useTranslation();

  const fetchData = useCallback(
    async (page, ScardName, SphoneNumber, SstartDate, SendDate) => {
      try {
        setLoading(true);

        let url = `api/cashier/outgoing-payments-and-chart-of-accounts?CardType=A&${ScardName ? `cardName=${ScardName}&` : ""}${SphoneNumber ? `PhoneNumber=${SphoneNumber}&` : ""}${SstartDate ? `startDate=${SstartDate}&` : ""}${SendDate ? `endDate=${SendDate}&` : ""}${page ? `Skip=${page}&` : ""}`;

        const { data } = await http.get(url);
        const formattedData = data.data;
        const maxsulotLengths = formattedData.length;

        const hasMore = maxsulotLengths === 20 ? true : false;
        setFData(formattedData);
        setHasMoreData(hasMore);
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    },
    [cardName, phoneNumber, startDate, endDate, slpName],
  );

  useEffect(() => {
    fetchData(currentPage, cardName, phoneNumber, startDate, endDate);
  }, [fetchData, currentPage, cardName, startDate, endDate, phoneNumber]);

  const handleNextPage = () => {
    if (hasMoreData) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  let searchInput = null;

  const handleDateRangeChange = (dates, confirm, dataIndex) => {
    debouncedSearch(dates, dataIndex, confirm);
  };

  const handleStartDate = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDate = (e) => {
    setEndDate(e.target.value);
  };

  const debouncedSearch = useMemo(
    () =>
      debounce((value, dataIndex, confirm) => {
        if (dataIndex === "cardName") {
          if (value) {
            setCardName(value);
          } else {
            setCardName("");
          }
        } else if (dataIndex === "phoneNumber") {
          if (value) {
            setPhoneNumber(value);
          } else {
            setPhoneNumber("");
          }
        } else if (dataIndex === "sana") {
          if (value) {
            const formattedStartDate = new Date(value[0]).toLocaleString();
            const formattedEndDate = new Date(value[1]).toLocaleString();

            if (formattedStartDate && formattedEndDate) {
              setStartDate(moment(formattedStartDate).format("YYYY-MM-DD"));
              setEndDate(moment(formattedEndDate).format("YYYY-MM-DD"));
            }
          } else {
            setStartDate("");
            setEndDate("");
          }
        } else {
        }
        confirm();
      }, 3000),
    [fetchData, currentPage],
  );
  const getColumnSearchProps = (dataIndex) => {
    if (dataIndex === "sana") {
      return {
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
          <div style={{ padding: 8 }}>
            <RangePicker
              value={selectedKeys && selectedKeys[0] ? selectedKeys : []}
              onChange={(dates) => {
                setSelectedKeys(dates ? dates : []);
                handleDateRangeChange(dates, confirm, dataIndex);
              }}
              style={{ marginBottom: 8, display: "block" }}
              disabledDate={(current) =>
                current && current > moment().endOf("day")
              }
            />
          </div>
        ),
        filterIcon: (filtered) => (
          <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
        ),
        onFilter: (value, record) => {
          if (Array.isArray(value) && value.length === 2) {
            const [startDate, endDate] = value;
            const recordDate = moment(record[dataIndex]);
            return recordDate.isBetween(startDate, endDate, "[]");
          }
          return true;
        },
        render: (text) => text,
      };
    }
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`${t("search")} ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              handleSearch(e, confirm, dataIndex);
            }}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          ? record[dataIndex]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : "",
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.select(), 100);
        }
      },
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        ) : (
          text
        ),
    };
  };
  const handleSearch = (e, confirm, dataIndex) => {
    const value = e.target.value;
    debouncedSearch(value, dataIndex, confirm);
  };

  const columns = columnAcceptPaymentCustomer(t, getColumnSearchProps);

  return (
    <>
      <h1 className="font-poppins ml-4 mt-10 text-xl font-bold text-black sm:ml-5 sm:mt-14 sm:text-2xl">
        {t("expenses")}
      </h1>
      <div className="mt-10 w-full border-[1px] border-[#E8E8E8] sm:mt-14"></div>

      <div className="ml-5 mt-5 flex gap-5">
        <div className="flex flex-col gap-3">
          <p>{t("startDate")}</p>
          <Input
            type="date"
            value={startDate}
            className="w-[150px]"
            onChange={handleStartDate}
            max={endDate}
          />
        </div>
        <div className="flex flex-col gap-3">
          <p>{t("endDate")}</p>
          <Input
            type="date"
            value={endDate}
            className="w-[150px]"
            onChange={handleEndDate}
            min={startDate}
            max={formattedDate}
          />
        </div>
      </div>
      <div className="mt-6 flex w-full items-center justify-end gap-4 px-4 sm:mt-10 sm:gap-7 sm:px-10">
        <button
          onClick={() => setIsModalVisible(true)}
          className="h-[40px] w-[150px] rounded-2xl bg-[#0A4D68] text-white sm:w-[200px]"
        >
          {t("Add")}
        </button>
      </div>
      <div className="ml-4 mt-6 sm:ml-5 sm:mt-10">
        <div className="mb-4 flex flex-col justify-between sm:flex-row">
          <div className="font-nunitto font-bold">
            {t("page")} : {currentPage + 1}
          </div>
          <div className="mt-2 flex gap-2 sm:mr-10">
            <button
              onClick={handlePreviousPage}
              disabled={currentPage === 0}
              className="h-[40px] w-[100px] rounded-2xl bg-gray-300 text-gray-700 disabled:bg-gray-200 disabled:text-gray-400 sm:w-[100px]"
            >
              {t("previous")}
            </button>
            <button
              onClick={handleNextPage}
              disabled={!hasMoreData}
              className="h-[40px] w-[100px] rounded-2xl bg-[#0A4D68] text-white disabled:bg-gray-200 disabled:text-gray-400 sm:w-[100px]"
            >
              {t("next")}
            </button>
          </div>
        </div>
        <div className="m-2 overflow-x-auto">
          <Table
            columns={columns}
            dataSource={fdata}
            pagination={false}
            className="mt-6 w-full sm:mt-10"
            rowKey={(record) => record.docEntry}
            sticky={true}
            scroll={{ x: 900 }}
            loading={loading}
          />
        </div>
      </div>
      {/* <Header
        title={t("To'lov yaratish")}
        currentPage={currentPage + 1}
        handlePreviousPage={handlePreviousPage}
        handleNextPage={handleNextPage}
        hasMoreData={hasMoreData}
        columns={columns}
        fdata={fdata}
        onBtnClick={() => {}}
        loading={loading}
        handleRowClick={() => {}}
        onStartDateChange={handleStartDate}
        onEndDateChange={handleEndDate}
        startDate={startDate}
        endDate={endDate}
        formattedDate={formattedDate}
      /> */}
      <CreatePaymentAccount
        refetchTable={fetchData}
        isModalVisible={isModalVisible}
        onCloseModal={() => setIsModalVisible(false)}
      />
    </>
  );
};

export default Xarajatlar;
