import { Modal, Table, message, Tooltip } from "antd";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CloseOutlined } from "@ant-design/icons";
import { http } from "../../../services/http";
import { numberWithSpacesIntl } from "../../../utils/numberWithSpaces";
import moment from "moment";
const OriginalFileModal = ({ visible, data, onClose, code }) => {
  const { t } = useTranslation();

  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = async (docEntry) => {
    setLoading(true);
    try {
      const { data } = await http.get(
        `api/bankstatements/bank-statements?docEntry=${docEntry}&pageSize=1000`,
      );
      setTableData(data);
    } catch (err) {
      message.error(err?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (data) {
      fetchData(data?.u_OriginalDataID);
    }
  }, [data, code, visible]);

  const columns = [
    {
      title: t("paymentNum"),
      dataIndex: "u_PaymentNum",
      key: "u_PaymentNum",
    },
    {
      title: t("companyName"),
      dataIndex: "u_CompanyName",
      key: "u_CompanyName",
    },
    {
      title: t("paymentDate"),
      dataIndex: "u_PaymentDate",
      key: "u_PaymentDate",
      render: (value) => moment(value).format("DD.MM.YYYY HH:mm:ss"),
    },
    {
      title: t("debit2"),
      dataIndex: "u_Debit",
      key: "u_Debit",
      render: (text) => {
        const formattedText = numberWithSpacesIntl(text);
        return `${formattedText} `;
      },
    },
    {
      title: t("credit2"),
      dataIndex: "u_Credit",
      key: "u_Credit",
      render: (text) => {
        const formattedText = numberWithSpacesIntl(text);
        return `${formattedText} `;
      },
    },
    {
      title: t("comment"),
      dataIndex: "u_Description",
      key: "u_Description",
      render: (text, record) => {
        return (
          <Tooltip title={text}>
            <div className="cursor-pointer">{`${text && text.length > 30 ? `${text.slice(0, 30)}...` : text}`}</div>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <Modal
      title={
        <h1 className="font-nunito text-xl font-extrabold text-basic">
          {t("outgoingOriginalPayments")} - {code}
        </h1>
      }
      open={visible}
      onCancel={onClose}
      footer={null}
      closeIcon={<CloseOutlined />}
      width="70%"
      style={{ height: "80%" }}
    >
      <div className="w-full">
        <Table
          columns={columns}
          dataSource={tableData}
          pagination={false}
          loading={loading}
          className="border-1 mt-12 border-black"
          scroll={{ x: "100%" }}
        />
      </div>
    </Modal>
  );
};

export default OriginalFileModal;
