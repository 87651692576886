import React, { useState, useCallback, useEffect } from "react";
import { Modal, Input, Table, Button, message, Select } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { http } from "../../services/http";
import moment from "moment";

const RowDataFinish = ({
  visible,
  data,
  onClose,
  refetch,
  index,
  onSuccess,
}) => {
  const { t } = useTranslation();
  const employeeId = useSelector((state) => state.main.employeeId);

  const formattedDate = moment().format("YYYY-MM-DD");
  const fiveDaysAgo = moment().subtract(5, "days").format("YYYY-MM-DD");

  const [docTotalFC, setDocTotalFC] = useState(data?.docTotalFC);
  const [docTotal, setDocTotal] = useState(data?.docTotal);
  const [date, setDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedComments, setSelectedComments] = useState("");
  const [tableData, setTableData] = useState([]);

  const sum =
    data?.docCur === "UZS"
      ? new Intl.NumberFormat("fr-FR").format(docTotalFC)
      : new Intl.NumberFormat("fr-FR").format(docTotal);

  useEffect(() => {
    if (data) {
      console.log("Data = ", data);
      setTableData(
        data?.documentLines?.map((item, index) => ({
          ...item,
          key: index,
          uQuantity: item.quantity - item.openQty,
          lineTotal: item.price * item.openQty,
          totalFrgn: item.price * item.openQty,
        })),
      );
      console.log("docDate", data?.docDate);
      setDate(moment(data?.docDate).format("YYYY-MM-DD"));
      setDocTotalFC(data?.docTotalFC);
      setDocTotal(data?.docTotal);
    }
  }, [data]);

  const handleSelectClick = () => {
    setSelectedComments(data?.comments);
    setIsModalVisible(true);
  };

  const handleModalOk = () => {
    setIsModalVisible(false);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const handlePriceChange = useCallback(
    (e, index) => {
      const newTableData = [...tableData];
      newTableData[index].price = e.target.value;
      setTableData(newTableData);

      const newTotalPrice = newTableData?.reduce((total, item) => {
        console.log(item);
        return total + item.price * item.openQty;
      }, 0);

      setDocTotalFC(newTotalPrice);
      setDocTotal(newTotalPrice);
    },
    [tableData],
  );

  const columns = [
    {
      title: t("item"),
      dataIndex: "itemDescription",
      key: "itemDescription",
    },
    {
      title: t("quantityShipped"),
      dataIndex: "quantity",
      key: "quantity",
      render: (text) => {
        const formattedText = new Intl.NumberFormat("fr-FR").format(text);
        return `${formattedText}`;
      },
    },
    {
      title: t("quantityRemoved"),
      dataIndex: "uQuantity",
      key: "uQuantity",
      render: (text) => {
        const formattedText = new Intl.NumberFormat("fr-FR").format(text);
        return `${formattedText}`;
      },
    },
    {
      title: t("openQty"),
      dataIndex: "openQty",
      key: "openQty",
      render: (text) => {
        const formattedText = new Intl.NumberFormat("fr-FR").format(text);
        return `${formattedText}`;
      },
    },
    {
      title: t("unitMsr"),
      dataIndex: "measureUnit",
      key: "measureUnit",
    },
    {
      title: t("price"),
      dataIndex: "price",
      key: "price",
      render: (text, record, index) => (
        <Input
          type="number"
          value={text}
          min={0}
          onWheel={(e) => e.target.blur()}
          className="w-[90px]"
          disabled={data?.docStatus === "C"}
          onChange={(e) => handlePriceChange(e, index)}
        />
      ),
    },
    {
      title: t("inventoryQuantity"),
      dataIndex: "openInvQty",
      key: "openInvQty",
    },
    {
      title: t("ugpName"),
      dataIndex: "ugpName",
      key: "ugpName",
    },
    {
      title: t("totalMiqdor"),
      dataIndex: "lineTotal",
      key: "lineTotal",
      render: (text, record) => {
        const formatSum =
          record.currency === "UZS"
            ? new Intl.NumberFormat("fr-FR").format(record.totalFrgn)
            : new Intl.NumberFormat("fr-FR").format(text);
        return `${formatSum} ${record.currency}`;
      },
    },
    {
      title: t("whs"),
      dataIndex: "whsName",
      key: "whsName",
    },
  ];

  const postData = async () => {
    setLoading(true);
    try {
      const documentLines = tableData
        .filter((item) => item.openQty !== 0)
        .map((item) => ({
          baseEntry: data?.docEntry,
          baseLine: item.lineNum,
          itemCode: item.itemCode,
          quantity: item.openQty,
          warehouseCode: item.warehouseCode,
          price: +item.price,
          currency: item.currency,
        }));

      await http.post("api/yakunlangansotuvlar", {
        cardCode: String(data?.cardCode),
        u_Driver: data?.driver || "",
        u_Father_DocNum: data?.u_Father_DocNum || "",
        u_Responsible_Person: employeeId,
        salesPersonCode: data?.salesPersonCode,
        documentsOwner: employeeId,
        docCurrency: data?.docCur,
        docDueDate: date,
        docDate: date,
        documentLines: documentLines,
      });
      message.success(t("sucCreated"));

      setTimeout(() => {
        onClose();
        onSuccess(tableData);
      }, 800);
    } catch (error) {
      message.error(t("errCreation") + error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={
        <h1 className="font-nunito text-xl font-extrabold text-[#000000]">
          {t("order")} № {data?.docNum}
        </h1>
      }
      visible={visible}
      onCancel={onClose}
      footer={null}
      closeIcon={<CloseOutlined />}
      width="80%"
      style={{ maxHeight: "80vh", overflowY: "auto" }}
    >
      <div className="w-full px-5">
        <div className="w-full">
          <div className="mt-4 flex flex-col justify-between sm:mt-10 sm:flex-row">
            <div className="flex flex-col gap-3">
              <p className="font-nunito">{t("mijoz")}</p>
              <Input
                type="text"
                value={data?.cardName}
                style={{ color: "black" }}
                className="flex h-12 w-full items-center justify-center sm:w-[250px]"
                disabled
              />
            </div>
            <div className="mt-4 flex flex-col gap-3 sm:mt-0">
              <p className="font-nunito">{t("date")}</p>
              {data?.docStatus === "C" ? (
                <Input
                  type="text"
                  value={moment(data?.docDate).format("YYYY-MM-DD")}
                  style={{ color: "black" }}
                  disabled
                />
              ) : (
                <Input
                  type="date"
                  value={date}
                  style={{ color: "black" }}
                  onChange={(e) => {
                    setDate(e.target.value);
                  }}
                  max={formattedDate}
                  min={fiveDaysAgo}
                />
              )}
            </div>
          </div>
          <Table
            columns={columns}
            dataSource={tableData}
            pagination={false}
            className="border-1 mt-4 w-full border-black sm:mt-12"
            scroll={{ x: "100%" }}
          />
          <div className="mt-5 flex items-center justify-end gap-5">
            <div className="flex flex-col gap-3">
              <p>{t("docTotalQuantity")}</p>
              <Input
                type="text"
                value={`${data?.docTotalQuantity} кг`}
                style={{ color: "black" }}
                className="flex h-10 w-full items-center justify-center border-[1px] border-[#D0D0D0] active:border-[#D0D0D0] sm:w-[200px]"
                disabled
              />
            </div>

            <div className="flex flex-col gap-3">
              <p>{t("totalPrice")}</p>
              <Input
                type="text"
                value={`${sum} ${data?.docCur}`}
                style={{ color: "black" }}
                className="flex h-10 w-full items-center justify-center border-[1px] border-[#D0D0D0] active:border-[#D0D0D0] sm:w-[200px]"
                disabled
              />
            </div>
          </div>

          <div className="mt-4 flex flex-col justify-between sm:mt-10 sm:flex-row">
            <div className="flex flex-col gap-3">
              <p>{t("comments")}</p>
              <Select
                value={data?.comments}
                className="flex h-12 w-full items-center justify-center sm:w-[250px]"
                style={{ color: "black" }}
                disabled
                onClick={handleSelectClick}
              />
              <Modal
                title={t("comments")}
                visible={isModalVisible}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
              >
                <p>{selectedComments}</p>
              </Modal>
            </div>

            <div className="flex flex-col gap-3">
              <p>{t("driver")}</p>
              <Input
                value={data?.driver}
                className="flex h-12 w-full items-center justify-center sm:w-[200px]"
                style={{ color: "black" }}
                disabled
              />
            </div>

            <div className="mt-4 flex flex-col gap-3 sm:mt-0">
              <p>{t("responsiblePerson")}</p>
              <Input
                type="text"
                value={data?.slpName}
                disabled
                style={{ color: "black" }}
                className="flex h-12 w-full items-center justify-center border-[1px] border-[#D0D0D0] sm:w-[150px]"
              />
            </div>
          </div>

          <div className="mt-14">
            <Button
              onClick={postData}
              className="h-12 w-[300px] rounded-3xl bg-[#0A4D68] text-white"
              loading={loading}
              disabled={loading || data?.docStatus === "C"}
            >
              {t("finishSale")}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RowDataFinish;
