import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Menu, message } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import {
  setCollapse,
  setSelectedPath,
  setOpenMiniMenu,
} from "../../../slice/mainSlice";
import logo from "../../../assets/images/BIS logo.svg";
import "../../../assets/style/menu.css";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { http } from "../../../services/http";

import {
  Lightbulb,
  FileCheck,
  Upload,
  LayoutDashboard,
  FileText,
} from "lucide-react";

export const Menubar = () => {
  const { t } = useTranslation();
  const { collapse, selectedPath, openMiniMenu } = useSelector(
    (state) => state.main,
  );
  const dispatch = useDispatch();
  const [menuHeight, setMenuHeight] = useState("auto");
  const [loading, setLoading] = useState(false);
  const [subMenus, setSubMenus] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const menu = document.getElementById("menu");
    if (menu) {
      setMenuHeight(`${menu.scrollHeight}px`);
    }
  }, [collapse]);

  const toggleCollapsed = () => {
    dispatch(setCollapse(!collapse));
  };
  const onSelectMenuItem = (item) => {
    localStorage.setItem("menuName", item?.item?.props?.name);
    dispatch(setSelectedPath(item.key));
    navigate(`/${item.key}`);
    console.log(item);
  };

  const fetchSubMenus = async () => {
    setLoading(true);
    try {
      const { data } = await http.get(`api/accounts/menu`);
      setSubMenus(data);
    } catch (err) {
      message.error(err?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSubMenus();
  }, []);

  const bankState = subMenus?.map((item) => {
    const isConversion = item.u_IsConvertationAccount === "Yes";
    return {
      key: `${isConversion ? `conversion-accountant/${item.acctCode}` : `bank-statement/${item.acctCode}`}`,
      label: (
        <div className="text-wrap text-sm">{`${item.acctCode} - ${item.acctName}`}</div>
      ),
    };
  });

  const getMenuItems = () => {
    return [
      {
        key: "dashboard-accountant",
        icon: <LayoutDashboard style={{ fontSize: "25px", color: "white" }} />,
        label: t("dashboard"),
      },
      {
        key: "bankStatements",
        icon: <FileText style={{ color: "white", fontSize: "25px" }} />,
        label: t("bankStatements"),
        children: [
          ...subMenus?.map((item) => {
            const isConversion = item.u_IsConvertationAccount === "Yes";
            return {
              key: `${isConversion ? `conversion-accountant/${item.acctCode}` : `bank-statement/${item.acctCode}`}`,
              label: (
                <div className="text-wrap text-sm">{`${item.acctCode} - ${item.acctName}`}</div>
              ),
              name: item.acctName,
            };
          }),
        ],
      },
      {
        key: "loaded-payments",
        icon: <Upload style={{ color: "white", fontSize: "25px" }} />,
        label: t("loaded-payments"),
        children: [
          ...subMenus?.map((item) => ({
            key: `loaded-payments/${item.acctCode}`,
            label: (
              <div className="text-wrap text-sm">{`${item.acctCode} - ${item.acctName}`}</div>
            ),
            name: item.acctName,
          })),
        ],
      },
      {
        key: "act-sverka-accountant",
        icon: <FileCheck style={{ color: "white", fontSize: "25px" }} />,
        label: t("act-sverka"),
        children: [
          ...subMenus?.map((item) => ({
            key: `act-sverka-accountant/${item.acctCode}`,
            label: (
              <div className="text-wrap text-sm">{`${item.acctCode} - ${item.acctName}`}</div>
            ),
            name: item.acctName,
          })),
        ],
      },
      {
        key: "recommendation",
        icon: <Lightbulb style={{ color: "white", fontSize: "25px" }} />,
        label: t("recommendation"),
      },
    ];
  };

  const items = getMenuItems();

  return (
    <div className="m-0 flex h-screen">
      <div
        className={`${collapse ? "w-[80px]" : "w-[295px]"} h-${menuHeight} bg-[#0A4D68]`}
      >
        <div className="flex h-screen flex-col">
          <div className="mt-6 flex justify-between px-5">
            {collapse ? "" : <img src={logo} alt="" className="w-[150px]" />}

            <button
              onClick={toggleCollapsed}
              className="bg-[#0A4D68] text-2xl text-white"
            >
              {collapse ? (
                <MenuUnfoldOutlined className="ml-2" />
              ) : (
                <MenuFoldOutlined />
              )}
            </button>
          </div>
          <div className="mt-14 flex-1 overflow-y-auto">
            <Menu
              defaultOpenKeys={openMiniMenu}
              selectedKeys={[selectedPath]}
              mode="inline"
              inlineCollapsed={collapse}
              items={items}
              className="bg-transparent"
              theme="light"
              onSelect={onSelectMenuItem}
              onOpenChange={(v) => {
                dispatch(setOpenMiniMenu(v));
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menubar;
