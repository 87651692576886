import React, { useEffect, useState, useCallback } from "react";
import { Button, Form, Input, message, Modal, Select, Spin, Tag } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { getFiveDaysAgo, getToday } from "../../../utils/getDays";
import { http } from "../../../services/http";
import { useLocationParams } from "../../../hooks/use-location-params";
import moment from "moment";
import debounce from "lodash.debounce";
import {
  handleInputChangeMoney,
  removeSpaces,
} from "../../../utils/formatMoney";

const CreateIncomingPayments = ({ isModalVisible, refetchTable, onClose }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [partnersLoading, setPartnersLoading] = useState(false);
  const [rateLoading, setRateLoading] = useState(false);
  const [docDate, setDocDate] = useState(moment().format("YYYY-MM-DD"));
  const [partnersData, setPartnersData] = useState([]);
  const [docCurrency, setDocCurrency] = useState("UZS");
  const [docRate, setDocRate] = useState(0);
  const [docRateApi, setDocRateApi] = useState(0);
  const { query } = useLocationParams();
  const [skip, setSkip] = useState(0);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [limit, setLimit] = useState(0);
  const [limitDisabled, setLimitDisabled] = useState(true);
  const [searchValue, setSearchValue] = useState("");

  const handleScroll = (e) => {
    const { target } = e;
    if (target.scrollTop + target.offsetHeight >= target.scrollHeight - 10) {
      setSkip((prevSkip) => {
        const newSkip = prevSkip + 1;
        getBP(newSkip, searchValue);
        return newSkip;
      });
    }
  };

  const getCurrencyRate = async (docDate) => {
    try {
      setRateLoading(true);
      const { data } = await http.get(
        `api/cashier/currency-rate?Currency=UZS&Date=${docDate}`,
      );
      setDocRateApi(Number(data.data));
      setDocRate(Number(data.data));
      form.setFieldsValue({ docRate: Number(data.data) });
    } catch (err) {
      console.log(err);
    } finally {
      setRateLoading(false);
    }
  };
  const getBP = async (newSkip, searchValue = "") => {
    if (!hasMoreData) return;
    try {
      setRateLoading(true);
      const { data } = await http.get(
        `api/sales/business-partners?skip=${newSkip * 20 || 0}&cardType=cSupplier&isKassir=true&CardName=${searchValue}`,
      );
      const mappedItems = data.data.map((user) => ({
        label: (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <span>{user.cardName}</span>
            <Tag color="blue">{user.currency}</Tag>
          </div>
        ),
        value: user.cardCode,
        allowedLimit: user.allowedLimit,
        currentAccountBalance: user.currentAccountBalance,
      }));
      if (data.data.length < 20) {
        setHasMoreData(false);
      }
      setPartnersData((prevData) => [...prevData, ...mappedItems]);
    } catch (err) {
      console.log(err);
    } finally {
      setRateLoading(false);
    }
  };

  useEffect(() => {
    getBP();
    form.setFieldsValue({
      docDate: getToday(),
    });
  }, []);

  useEffect(() => {
    getCurrencyRate(docDate);
  }, [docDate]);

  const postData = async (values) => {
    try {
      setLoading(true);
      await http.post("api/sales/outgoing-payment", {
        ...values,
        // + "T00:00:00Z"
        docDate: values.docDate + "T00:00:00Z",
        docType: "rSupplier",
        cashSum: +removeSpaces(values.cashSum),
        docRate: +docRate,
      });
      refetchTable();
      message.success({ content: t("sucCreated") });
      onClose();
    } catch (err) {
      message.error({ content: JSON.stringify(err) });
    } finally {
      setLoading(false);
    }
  };

  const onCancel = () => {
    onClose();
    form.resetFields();
  };

  const fetchBpLimit = async (cardCode) => {
    try {
      setLoading(true);

      let url = `api/businesspartners/get-bp-limits?cardCode=${cardCode}`;
      const { data } = await http.get(url);

      const formattedData = data[0];
      const limit = Math.abs(
        Number(Math.abs(formattedData.balanceFC)) -
          Number(formattedData.u_AllowedLimit),
      );
      console.log("limit", limit);
      setLimit(limit);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const handleSearch = useCallback(
    debounce((value) => {
      setSearchValue(value);
      setHasMoreData(true); // Filterlashda yuklashni yana yoqish
      setPartnersData([]);
      getBP(0, value); // Filter so'rovini yuborish
    }, 300),
    [], // Debounce intervali (300ms)
  );

  return (
    <Modal
      open={isModalVisible}
      onCancel={onCancel}
      footer={null}
      closeIcon={<CloseOutlined />}
      width="80%"
      style={{ height: "80%" }}
    >
      <Spin spinning={rateLoading || loading}>
        <div className="w-full sm:px-14">
          <Form
            form={form}
            layout={"vertical"}
            onFinish={postData}
            initialValues={{
              docCurrency: "UZS",
            }}
          >
            <div className="mt-10 grid gap-3 sm:grid-cols-1 md:grid-cols-3">
              <Form.Item name="docDate" label={t("date")}>
                <Input
                  type="date"
                  min={getFiveDaysAgo()}
                  max={getToday()}
                  value={docDate}
                  onChange={(e) => {
                    setDocDate(e.target.value);
                  }}
                  className="h-12 font-bold"
                />
              </Form.Item>
              <Form.Item name="cardCode" label={t("driverAndEmployee")}>
                <Select
                  showSearch
                  className="h-12 font-normal"
                  options={partnersData}
                  loading={partnersLoading}
                  onSearch={handleSearch}
                  onPopupScroll={handleScroll}
                  onChange={(val, label) => {
                    fetchBpLimit(val);
                  }}
                />
              </Form.Item>
              <Form.Item
                name="docRate"
                label={t("Dollar kursi")}
                rules={[
                  {
                    required: true,
                    message: t("required"),
                  },

                  {
                    validator: (_, value) => {
                      if (
                        !value ||
                        (value >= docRateApi - 50 && value <= docRateApi + 50)
                      ) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          t("range_error", {
                            min: docRateApi - 50,
                            max: docRateApi + 50,
                          }),
                        ),
                      );
                    },
                  },
                ]}
              >
                <Input
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  value={docRate}
                  onChange={(e) => setDocRate(e.target.value)}
                  className="h-12"
                />
              </Form.Item>

              <Form.Item
                name="cashSum"
                label={t("Cash sum")}
                rules={[
                  {
                    required: true,
                    message: t("required"),
                  },
                  {
                    validator: (_, value) => {
                      // Remove spaces/commas and parse as integer
                      const val = parseInt(
                        value.toString().replace(/\s|,/g, ""),
                      );

                      if (!value || isNaN(val)) {
                        setLimitDisabled(true);
                      }

                      if (val >= 1 && val <= limit) {
                        setLimitDisabled(false);
                        return Promise.resolve();
                      } else {
                        setLimitDisabled(true);
                        return Promise.reject(
                          new Error(
                            t("rangeErrorCashSum", {
                              min: 1,
                              max: limit,
                            }),
                          ),
                        );
                      }
                    },
                  },
                ]}
              >
                <Input
                  className="h-12"
                  onChange={(e) => {
                    handleInputChangeMoney(e, form, "cashSum");

                    // Trigger validation on each change
                    form.validateFields(["cashSum"]);
                  }}
                  onWheel={(e) => e.target.blur()}
                />
              </Form.Item>

              <Form.Item name="docCurrency" label={t("currency")}>
                <Select
                  onChange={(value) => {
                    setDocCurrency(value);
                  }}
                  showSearch
                  className="h-12"
                  options={[
                    // { label: t("USD"), value: "USD" },
                    { label: t("UZS"), value: "UZS" },
                  ]}
                />
              </Form.Item>
              <Form.Item name="remarks" label={t("comments")}>
                <Input.TextArea rows={4} />
              </Form.Item>
            </div>
            <div className="mt-24 flex justify-center">
              <Button
                type={"primary"}
                htmlType={"submit"}
                className="h-12 w-full rounded-3xl bg-[#0A4D68] text-white hover:!bg-blue-950 sm:w-[350px]"
                disabled={
                  !(docRate >= docRateApi - 50 && docRate <= docRateApi + 50) ||
                  loading ||
                  limitDisabled
                }
                loading={loading}
              >
                {t("create")}
              </Button>
            </div>
          </Form>
        </div>
      </Spin>
    </Modal>
  );
};

export default CreateIncomingPayments;
