import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import LayoutAccountant from "../../../components/LayoutAccountant";
import {
  Tooltip,
  Table,
  Input,
  Select,
  AutoComplete,
  Tag,
  message,
} from "antd";
import { useTranslation } from "react-i18next";
import ChangeRateModal from "../../../components/Accountant/ChangeRate";
import { getToday } from "../../../utils/getDays";
import { debouncedSet } from "../../../utils/debounce";
import { useLocation } from "react-router-dom";
import { http } from "../../../services/http";
import { numberWithSpacesIntl } from "../../../utils/numberWithSpaces";
import moment from "moment";
import debounce from "lodash.debounce";

const Recomendations = ({ name }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const code = location.pathname.split("/")[2];
  const tableContainerRef = useRef(null);
  const isLoadingRef = useRef(false);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState(getToday());
  const [type, setType] = useState("");
  const [bpNameSelected, setBpNameSelected] = useState("");
  const [bpData, setBpData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [bpNameSearch, setBpNameSearch] = useState("");
  const [bpName, setBpName] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [docType, setDocType] = useState("");
  const pageSize = "20";
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(false);

  const fetchData = async ({
    page,
    pageSize,
    bpName,
    docType,
    isLoadMore = false,
  }) => {
    setLoading(true);
    if (isLoadingRef.current) return;

    try {
      isLoadingRef.current = true;
      if (!isLoadMore) {
        setLoading(true);
      } else {
        setIsLoadingMore(true);
      }
      const { data } = await http.get(
        `api/bankstatements/recommendations?bpName=${bpName}&docType=${docType}&skip=${page}&pageSize=${pageSize}`,
      );
      if (page === 0) {
        setData(data);
      } else {
        setData((prevData) => [...prevData, ...data]);
      }
      setHasMore(data.length === Number(pageSize));
    } catch (err) {
      message.error(err?.response?.data?.message);
      setHasMore(false);
    } finally {
      setLoading(false);
      setIsLoadingMore(false);
      isLoadingRef.current = false;
    }
  };

  const fetchBpName = async (value) => {
    try {
      const { data } = await http.get(
        `api/bankstatements/business-partners?cardName=${value}`,
      );
      setBpData(data);
    } catch (err) {
      message.error(err?.response?.data?.message);
    }
  };

  useEffect(() => {
    fetchData({
      page: currentPage,
      pageSize,
      bpName,
      docType,
      isLoadMore: currentPage > 0,
    });
  }, [currentPage, bpName, code, docType]);

  useEffect(() => {
    fetchBpName(bpNameSearch);
  }, [bpNameSearch]);

  const columns = [
    {
      title: t("u_DocType"),
      dataIndex: "u_DocType",
      key: "u_DocType",
      render: (text) => {
        return (
          <span>
            {text === "A"
              ? t("account")
              : text === "C"
                ? t("customer")
                : text === "S"
                  ? t("supplier")
                  : text}
          </span>
        );
      },
    },
    {
      title: t("bpCode"),
      dataIndex: "u_AcctCode",
      key: "u_AcctCode",
      render: (text, record) => {
        return (
          <span>{record?.u_DocType === "A" ? text : record?.u_BPCode}</span>
        );
      },
    },
    {
      title: t("bpName"),
      dataIndex: "u_AcctName",
      key: "u_AcctName",
      render: (text, record) => {
        return (
          <span>{record?.u_DocType === "A" ? text : record?.u_BPName}</span>
        );
      },
    },
    {
      title: t("keyword"),
      dataIndex: "u_Keyword",
      key: "u_Keyword",
    },

    {
      title: t("currency"),
      dataIndex: "u_BPCurrency",
      key: "u_BPCurrency",
    },
  ];

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const handleSearchBpName = (value) => {
    debouncedSet(value, setBpNameSearch);
  };

  const debouncedSetPage = useMemo(
    () =>
      debounce(() => {
        if (!isLoadingRef.current) {
          setCurrentPage((prev) => prev + 1);
        }
      }, 300),
    [],
  );

  const handleScroll = useCallback(
    (e) => {
      if (!hasMore || loading || isLoadingMore || isLoadingRef.current) return;

      const { scrollTop, scrollHeight, clientHeight } = e.target;

      if (scrollHeight - scrollTop <= clientHeight + 50) {
        debouncedSetPage();
      }
    },
    [hasMore, loading, isLoadingMore, debouncedSetPage],
  );

  useEffect(() => {
    const tableBody =
      tableContainerRef.current?.getElementsByClassName("ant-table-body")[0];
    if (tableBody) {
      tableBody.addEventListener("scroll", handleScroll);
      return () => tableBody.removeEventListener("scroll", handleScroll);
    }
  }, [handleScroll]);

  return (
    <LayoutAccountant>
      <div className="p-5 px-10">
        <h1 className="text-2xl font-bold text-basic">
          {t("recommendations")}
        </h1>
        <div className="mt-10 rounded-lg border-t-4 border-t-basic bg-white p-5 drop-shadow-md">
          <span className="text-xl font-bold text-basic">
            {t("filterRecommendations")}
          </span>
          <div className="mt-5 grid grid-cols-2 items-end gap-5 sm:grid-cols-3 lg:grid-cols-3">
            <div className="flex flex-col items-start gap-2">
              <span className="font-semibold text-basic">{t("naming")}</span>
              <Tooltip title={bpNameSelected}>
                <AutoComplete
                  className="w-full"
                  value={bpNameSelected}
                  options={bpData
                    .filter((item) =>
                      docType !== ""
                        ? item.cardType === docType
                        : item.cardType,
                    )
                    .map((item) => {
                      return {
                        value: item.cardName,
                        label: (
                          <Tooltip title={item.cardName} className="w-full">
                            <span className="w-full">{item.cardName}</span>
                          </Tooltip>
                        ),
                        cardName: item.cardName,
                      };
                    })}
                  onChange={(value) => {
                    handleSearchBpName(value);
                    setBpNameSelected(value);
                    setBpData([]);
                    setCurrentPage(0);
                    if (!value) {
                      setBpName("");
                    }
                  }}
                  onSelect={(value, label) => {
                    setBpNameSelected(label.cardName);
                    handleSearchBpName("");
                    setBpName(value);
                  }}
                  showSearch
                />
              </Tooltip>
            </div>
            <div className="flex flex-col items-start gap-2">
              <span className="font-semibold text-basic">{t("Tasnif")}</span>
              <Select
                className="w-full"
                value={docType}
                onChange={(value) => {
                  setData([]);
                  setDocType(value);
                  setBpNameSelected("");
                  setBpName("");
                  setCurrentPage(0);
                }}
                options={[
                  { value: "", label: t("all") },
                  { value: "A", label: t("account") },
                  { value: "C", label: t("customer") },
                  { value: "S", label: t("supplier") },
                ]}
              />
            </div>
          </div>
        </div>
        <div className="mt-10 rounded-lg border-t-4 border-t-basic bg-white drop-shadow-md">
          <div className="p-5">
            <div className="flex items-center justify-between">
              <span className="text-xl font-bold text-basic">
                {t("recommendations")}
              </span>
            </div>
            <div className="mt-5 overflow-x-auto" ref={tableContainerRef}>
              <Table
                columns={columns}
                dataSource={data}
                className="mt-10"
                pagination={false}
                loading={loading || isLoadingMore}
                rowKey={(record) => record.docNum}
                scroll={{
                  y: "calc(100vh - 300px)",
                  scrollToFirstRowOnChange: false,
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <ChangeRateModal
        visible={isModalVisible}
        data={data}
        onClose={handleModalClose}
      />
    </LayoutAccountant>
  );
};

export default Recomendations;
