import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import LayoutAccountant from "../../../components/LayoutAccountant";
import {
  Button,
  Table,
  Input,
  message,
  AutoComplete,
  Select,
  Tag,
  Tooltip,
} from "antd";
import { useTranslation } from "react-i18next";
import { getToday } from "../../../utils/getDays";
import { debouncedSet } from "../../../utils/debounce";
import { useLocation } from "react-router-dom";
import { http } from "../../../services/http";
import debounce from "lodash.debounce";
import moment from "moment";
import { removeSpaces } from "../../../utils/formatMoney";
import { numberWithSpacesIntl } from "../../../utils/numberWithSpaces";

const ConversionAccountant = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const code = location.pathname.split("/")[2];
  const tableContainerRef = useRef(null);
  const isLoadingRef = useRef(false);
  const menuName = localStorage.getItem("menuName") ?? "";

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState(getToday());
  const [bpData, setBpData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [bpNameSearch, setBpNameSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const pageSize = "20";
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  const fetchData = async ({
    page,
    pageSize,
    startDate,
    endDate,
    isLoadMore = false,
  }) => {
    setLoading(true);
    if (isLoadingRef.current) return;

    try {
      isLoadingRef.current = true;
      if (!isLoadMore) {
        setLoading(true);
      } else {
        setIsLoadingMore(true);
      }
      const { data } = await http.get(
        `api/payments/convertation-payments?startDate=${startDate}&endDate=${endDate}&skip=${page}&pageSize=${pageSize}`,
      );
      const mappedData = data.map((item) => ({
        ...item,
        key: item.docEntry,
        rate: 0,
        from: "UZS",
        to: "",
        bpCode: "",
      }));
      if (page === 0) {
        setData(mappedData);
      } else {
        setData((prevData) => [...prevData, ...mappedData]);
      }
      setHasMore(data.length === Number(pageSize));
    } catch (err) {
      message.error(err?.response?.data?.message);
      setHasMore(false);
    } finally {
      setLoading(false);
      setIsLoadingMore(false);
      isLoadingRef.current = false;
    }
  };

  const fetchBpName = async (value) => {
    try {
      const { data } = await http.get(
        `api/bankstatements/business-partners?cardName=${value}&cardType=S`,
      );
      setBpData(data);
    } catch (err) {
      message.error(err?.response?.data?.message);
    }
  };

  useEffect(() => {
    fetchData({
      page: currentPage,
      pageSize,
      startDate,
      endDate,
      isLoadMore: currentPage > 0,
    });
  }, [currentPage, pageSize, startDate, endDate, code]);

  useEffect(() => {
    fetchBpName(bpNameSearch);
  }, [bpNameSearch]);

  const columns = [
    {
      title: t("paymentDate"),
      dataIndex: "docDate",
      key: "docDate",
      render: (value) => (value ? moment(value).format("DD-MM-YYYY") : "-"),
    },
    {
      title: t("supplierName"),
      dataIndex: "bpName",
      key: "bpName",
      width: 280,
      render: (text, record, index) => {
        return (
          <AutoComplete
            value={text}
            options={bpData.map((callbackfn) => ({
              value: callbackfn.cardCode,
              label: (
                <div className="flex items-center justify-between">
                  <Tag color="blue">{callbackfn.cardCode}</Tag>
                  <span>{callbackfn.cardName}</span>
                </div>
              ),
              cardName: callbackfn.cardName,
            }))}
            className="w-[250px]"
            onChange={(val, label) => {
              handleSearchBp(val);
              const newData = [...data];
              newData[index].bpCode = "";
              newData[index].bpName = val;
              setData(newData);
            }}
            onSelect={(val, label) => {
              const newData = [...data];
              newData[index].bpCode = val;
              newData[index].bpName = label.cardName;
              setData(newData);
              handleSearchBp("");
            }}
          />
        );
      },
    },
    {
      title: t("conversionType"),
      dataIndex: "conversionType",
      key: "conversionType",
      width: 100,
      render: (text, record, index) => {
        return (
          <Select
            value={text}
            options={[
              {
                value: "",
                label: "",
                disabled: true,
                backgroundColor: "gray",
              },
              {
                value: "USD",
                label: "USD",
              },
              {
                value: "RUB",
                label: "RUB",
              },
            ]}
            className="w-[80px]"
            onChange={(val, label) => {
              console.log(val, label);
              const newData = [...data];
              newData[index].from = "UZS";
              newData[index].to = val;
              setData(newData);
            }}
          />
        );
      },
    },

    {
      title: t("paymentAmount"),
      dataIndex: "cashSum",
      key: "cashSum",
      width: 150,
      render: (text, record) => {
        return (
          <span>{`${new Intl.NumberFormat("fr-FR").format(text)} ${switchCurrency(record.docCurr)}`}</span>
        );
      },
    },
    {
      title: t("SAPRate"),
      dataIndex: "docRate",
      key: "docRate",
      render: (text, record) => {
        return (
          <span>{`${new Intl.NumberFormat("fr-FR").format(text)} ${switchCurrency(record.docCurr)}`}</span>
        );
      },
    },
    {
      title: t("rate"),
      dataIndex: "rate",
      key: "rate",
      render: (text, record, index) => {
        return (
          <Input
            value={text}
            onChange={(e) => {
              const newData = [...data];
              newData[index].rate = e.target.value;
              setData(newData);
            }}
            className="w-[80px]"
          />
        );
      },
    },
    {
      title: t("u_Firma"),
      dataIndex: "u_Firma",
      key: "u_Firma",
      render: (text, record) => {
        return (
          <Tooltip title={text}>
            <div className="cursor-pointer">{`${text && text.length > 30 ? `${text.slice(0, 30)}...` : text}`}</div>
          </Tooltip>
        );
      },
    },
  ];

  const onBtnClick = async (data) => {
    setBtnLoading(true);

    const postData = data.map((item) => {
      return {
        from: item.from,
        docEntry: item.docEntry,
        to: item.to,
        docDate: item.docDate,
        bpCode: item.bpCode,
        bpType: "S", // Doim Shunaqa beriladi
        cashSum: item.cashSum,
        docRate: parseFloat(item.rate), // input qilingan qiytmat beriladi
        u_Firma: item.u_Firma,
      };
    });

    try {
      await http.post("api/payments/create-batch", postData);
      message.success("Успешно сохранено!");
      refetch();
    } catch (error) {
      message.error(error?.response?.data?.message);
    } finally {
      setBtnLoading(false);
    }
  };

  const handleSearchBp = (value) => {
    debouncedSet(value, setBpNameSearch);
  };

  const switchCurrency = (currency) => {
    switch (currency) {
      case "UZS":
        return "so'm";
      case "USD":
        return "$";
      case "EUR":
        return "€";
      case "RUB":
        return "₽";
      default:
        return "so'm";
    }
  };

  const debouncedSetPage = useMemo(
    () =>
      debounce(() => {
        if (!isLoadingRef.current) {
          setCurrentPage((prev) => prev + 1);
        }
      }, 300),
    [],
  );

  const handleScroll = useCallback(
    (e) => {
      if (!hasMore || loading || isLoadingMore || isLoadingRef.current) return;

      const { scrollTop, scrollHeight, clientHeight } = e.target;

      if (scrollHeight - scrollTop <= clientHeight + 50) {
        debouncedSetPage();
      }
    },
    [hasMore, loading, isLoadingMore, debouncedSetPage],
  );

  useEffect(() => {
    const tableBody =
      tableContainerRef.current?.getElementsByClassName("ant-table-body")[0];
    if (tableBody) {
      tableBody.addEventListener("scroll", handleScroll);
      return () => tableBody.removeEventListener("scroll", handleScroll);
    }
  }, [handleScroll]);

  const refetch = () => {
    setCurrentPage(0);
    fetchData({
      page: 0,
      pageSize,
      startDate,
      endDate,
      isLoadMore: currentPage > 0,
    });
  };

  return (
    <LayoutAccountant>
      <div className="p-5 px-10">
        <h1 className="text-2xl font-bold text-basic">
          {t("Konvertatsiya")}-{code}-{menuName}
        </h1>

        <div className="mt-10 rounded-lg border-t-4 border-t-basic bg-white drop-shadow-md">
          <div className="p-5">
            <div className="mt-5 flex items-center justify-end">
              <Button
                type="primary"
                loading={btnLoading}
                className="bg h-9 w-auto bg-basic text-white hover:!bg-blue-900"
                onClick={() => {
                  onBtnClick(data);
                }}
                disabled={
                  !data?.every((item) => item.rate && item.bpCode && item.to)
                }
              >
                {t("uploadToSAP")}
              </Button>
            </div>

            <div className="mt-5 overflow-x-auto" ref={tableContainerRef}>
              <Table
                columns={columns}
                dataSource={data}
                className="mt-10"
                pagination={false}
                loading={loading || isLoadingMore}
                rowKey={(record) => record.docNum}
                scroll={{
                  y: "calc(100vh - 300px)",
                  scrollToFirstRowOnChange: false,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </LayoutAccountant>
  );
};

export default ConversionAccountant;
