import React, { useState, useEffect } from "react";
import { Modal, Input, Table, Button, message } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { http } from "../../services/http";

const TransportItemsQuantityModal = ({
  visible,
  data,
  onClose,
  refetch,
  customDocEntry,
  transportDocEntry,
  partyNumber,
  numberOfTransport,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState("");
  const [dataSource, setDataSource] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [docEntry, setDocEntry] = useState(null);

  const getSingleData = async (docEntry) => {
    setLoadingData(true);
    try {
      const { data } = await http.get(
        `api/customs/transport-items?docEntry=${docEntry}&pageSize=100&skip=0`,
      );
      setDataSource(data);
    } catch (error) {
      // message.error(error?.response?.data?.message);
      console.log("Error:", error?.response?.data?.message);
    } finally {
      setLoadingData(false);
    }
  };

  useEffect(() => {
    if (docEntry) {
      getSingleData(docEntry);
    }
  }, [docEntry]);

  useEffect(() => {
    if (data) {
      setDocEntry(data?.u_TransportItems);
      console.log("Data = ", data);
    }
  }, [visible, data, customDocEntry, transportDocEntry]);

  if (!data) return null;

  const columns = [
    {
      title: t("item"),
      dataIndex: "u_ItemName",
      key: "u_ItemName",
    },
    {
      title: t("quantity"),
      dataIndex: "u_Quantity",
      key: "u_Quantity",
      render: (text, record) => {
        const formattedText = new Intl.NumberFormat("fr-FR").format(text);
        return `${formattedText} `;
      },
    },
    {
      title: t("unitMsr"),
      dataIndex: "u_UoM",
      key: "u_UoM",
    },
  ];

  const postData = async () => {
    setLoading(true);

    try {
      const stockTransferLines = dataSource?.map((item, idx) => {
        return {
          itemCode: item.u_ItemCode,
          quantity: item.u_Quantity,
          lineNum: idx,
          warehouseCode: "VAGON",
          fromWarehouseCode: "01",
        };
      });
      await http.post(
        `api/inventorytransferrequest/customs-stock-transfer?customDocEntry=${customDocEntry ?? ""}&transportDocEntry=${transportDocEntry ?? ""}&transportLineId=${data?.lineId ?? ""}&partyNumber=${partyNumber ?? ""}&numberOfTransport=${numberOfTransport ?? ""}`,
        {
          toWarehouse: "VAGON",
          fromWarehouse: "01",
          stockTransferLines,
        },
      );
      message.success(t("sucCreated"));

      setTimeout(() => {
        onClose();
        refetch();
      }, 800);
    } catch (error) {
      console.log("Error");
      message.error(error?.response?.data?.message);
      console.log("Error:", error?.response?.data?.message?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={
        <h1 className="font-nunito text-xl font-extrabold text-[#000000]">
          {t("order")} № {data.docNum}
        </h1>
      }
      open={visible}
      onCancel={onClose}
      onRequestClose={onClose}
      footer={null}
      closeIcon={<CloseOutlined />}
      width="80%"
      bodyStyle={{ maxHeight: "80vh", overflowY: "auto" }}
    >
      <div className="w-full px-4 sm:px-5">
        <div className="w-full">
          {/* <div className="mt-5 flex items-center justify-between gap-5">
            <div className="flex flex-col gap-3">
              <p>{t("namePur")}</p>
              <Input
                type="text"
                value={data?.cardName}
                style={{ color: "black" }}
                className="flex h-10 w-full items-center justify-center border-[1px] border-[#D0D0D0] active:border-[#D0D0D0] sm:w-[200px]"
                disabled
              />
            </div>

            <div className="flex flex-col gap-3">
              <p>{t("typeOfTransport")}</p>
              <Input
                type="text"
                value={data.u_typeOfTransport || "-"}
                style={{ color: "black" }}
                className="flex h-10 w-full items-center justify-center border-[1px] border-[#D0D0D0] active:border-[#D0D0D0] sm:w-[150px]"
                disabled
              />
            </div>
            <div className="flex flex-col gap-3">
              <p>{t("numberOfTransport")}</p>
              <Input
                type="text"
                value={data.transport || "-"}
                style={{ color: "black" }}
                className="flex h-10 w-full items-center justify-center border-[1px] border-[#D0D0D0] active:border-[#D0D0D0] sm:w-[150px]"
                disabled
              />
            </div>
            <div className="flex flex-col gap-3">
              <p>{t("typeOfTransport")}</p>
              <Input
                type="text"
                value={data.u_typeOfTransport || "-"}
                style={{ color: "black" }}
                className="flex h-10 w-full items-center justify-center border-[1px] border-[#D0D0D0] active:border-[#D0D0D0] sm:w-[150px]"
                disabled
              />
            </div>
            <div className="flex flex-col gap-3">
              <p>{t("partyNumber")}</p>
              <Input
                type="text"
                value={data.u_PartyNumber || "-"}
                style={{ color: "black" }}
                className="flex h-10 w-full items-center justify-center border-[1px] border-[#D0D0D0] active:border-[#D0D0D0] sm:w-[170px]"
                disabled
              />
            </div>
          </div> */}
          <Table
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            loading={loadingData}
            className="border-1 mt-4 w-full border-black sm:mt-12"
            scroll={{ x: "100%" }}
          />

          <div className="mt-5 flex items-center justify-end gap-5">
            <Button
              type="primary"
              onClick={postData}
              className="h-12 w-full rounded-3xl bg-[#0A4D68] text-white sm:w-[200px]"
              disabled={
                data?.u_IsTransferMade === "Yes" ||
                data?.u_TransferAllowed !== "Yes"
              }
              loading={loading}
            >
              {t("transfer")}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TransportItemsQuantityModal;
