import React from "react";
import Navbar from "../navbar";
import { Menubar } from "../Accountant/Menu";

export default function LayoutAccountant({ children }) {
  return (
    <div className="flex h-full w-full overflow-y-auto">
      <Menubar />
      <div className="h-screen w-full overflow-y-auto">
        <Navbar />
        {children}
      </div>
    </div>
  );
}
