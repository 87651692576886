import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Input, Select, Tag } from "antd";
import Menubar from "../../../components/WhrManager/App";
import { http } from "../../../services/http";
import { SearchOutlined } from "@ant-design/icons";
import debounce from "lodash.debounce";
import Highlighter from "react-highlight-words";
import { useTranslation } from "react-i18next";
import moment from "moment";
import HeaderWithInfinityScroll from "../../../components/HeaderWithInfinityScroll";
import DirectModal from "../../../components/WhrManager/DirectModal";
import TransportModal from "../../../components/WhrManager/TransportModal";

const PurchaseDelivery = () => {
  const { t } = useTranslation();
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [typeOfTransport, setTypeOfTransport] = useState("");
  const [numberOfTransport, setNumberOfTransport] = useState("");
  const [partyNumber, setPartyNumber] = useState("");
  const [directionType, setDirectionType] = useState("");
  const [total, setTotal] = useState("");
  const [isDirectModalVisible, setIsDirectModalVisible] = useState(false);
  const [isTransportModalVisible, setIsTransportModalVisible] = useState(false);

  const orderStatus = [
    { label: "Barchasi", value: "" },
    { label: "So'rov yuborilsin", value: "SurovYuborilsin" },
    { label: "So'rov yuborildi", value: "SurovYuborildi" },
    { label: "Yuk ko'chirildi", value: "YukKochirildi" },
  ];

  const [selectedCardName, setSelectedCardName] = useState("");
  const [selectedDocNum, setSelectedDocNum] = useState("");

  const fetchData = useCallback(
    async (
      CardName,
      DocNum,
      startDate,
      endDate,
      TypeOfTransport,
      NumberOfTransport,
      PartyNumber,
      directionType,
      page,
      isLoadMore = false,
    ) => {
      try {
        setLoading(true);

        let url = `api/customs/customs?cardName=${CardName}&startDate=${startDate}&endDate=${endDate}&docNum=${DocNum}&typeOfTransport=${TypeOfTransport}&transportNumber=${NumberOfTransport}&partyNumber=${PartyNumber}&directionType=${directionType}&pageSize=20&skip=${page}`;

        const { data } = await http.get(url);
        if (page === 0) {
          console.log(page, data);
          setDataSource(data);
        } else {
          setDataSource((prevData) => [...prevData, ...data]);
        }
        const hasMore = data.length === 20 ? true : false;
        setHasMoreData(hasMore);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    },
    [
      selectedCardName,
      selectedDocNum,
      startDate,
      endDate,
      typeOfTransport,
      numberOfTransport,
      partyNumber,
      directionType,
      currentPage,
    ],
  );

  useEffect(() => {
    fetchData(
      selectedCardName,
      selectedDocNum,
      startDate,
      endDate,
      typeOfTransport,
      numberOfTransport,
      partyNumber,
      directionType,
      currentPage,
      currentPage > 0,
    );
  }, [
    fetchData,
    selectedCardName,
    selectedDocNum,
    startDate,
    endDate,
    typeOfTransport,
    numberOfTransport,
    partyNumber,
    directionType,
    currentPage,
  ]);

  const handleNextPage = () => {
    if (hasMoreData) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleRowClick = (record) => {
    setSelectedRowData(record);
    if (record.u_DirectionType === "transit") {
      setIsTransportModalVisible(true);
    } else if (record.u_DirectionType === "direct") {
      setIsDirectModalVisible(true);
    }
  };

  const handleStartDate = (e) => {
    setCurrentPage(0);
    setStartDate(e.target.value);
  };

  const handleEndDate = (e) => {
    setCurrentPage(0);

    setEndDate(e.target.value);
  };

  let searchInput = null;

  const debouncedSearch = useMemo(
    () =>
      debounce((value, dataIndex, confirm) => {
        if (dataIndex === "docNum") {
          if (value) {
            // setCurrentPage(0);

            setSelectedDocNum(value);
          } else {
            // setCurrentPage(0);

            setSelectedDocNum("");
          }
        }
        if (dataIndex === "cardName") {
          if (value) {
            // setCurrentPage(0);

            setSelectedCardName(value);
          } else {
            // setCurrentPage(0);

            setSelectedCardName("");
          }
        }
        if (dataIndex === "u_typeOfTransport") {
          if (value) {
            // setCurrentPage(0);

            setTypeOfTransport(value);
          } else {
            // setCurrentPage(0);

            setTypeOfTransport("");
          }
        }
        if (dataIndex === "tansportNumber") {
          if (value) {
            // setCurrentPage(0);

            setNumberOfTransport(value);
          } else {
            // setCurrentPage(0);

            setNumberOfTransport("");
          }
        }
        if (dataIndex === "u_PartyNumber") {
          if (value) {
            // setCurrentPage(0);

            setPartyNumber(value);
          } else {
            // setCurrentPage(0);

            setPartyNumber("");
          }
        }
        if (dataIndex === "u_DirectionType") {
          if (value) {
            setDirectionType(value);
          } else {
            setDirectionType("");
          }
        }
        setCurrentPage(0);
        confirm();
      }, 1000),
    [],
  );

  const handleSearch = (e, confirm, dataIndex) => {
    const value = e.target.value;

    debouncedSearch(value, dataIndex, confirm);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            handleSearch(e, confirm, dataIndex);
          }}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: t("namePur"),
      dataIndex: "cardName",
      key: "cardName",
      width: 150,
      ...getColumnSearchProps("cardName"),
    },
    {
      title: t("date"),
      dataIndex: "docDate",
      key: "docDate",
      width: 120,
      render: (text, record) => {
        return moment(text).format("DD-MM-YYYY");
      },
      // ...getColumnSearchProps("sana"),
    },
    {
      title: t("numPur"),
      dataIndex: "docNum",
      key: "docNum",
      width: 120,
      ...getColumnSearchProps("docNum"),
    },

    {
      title: t("typeOfTransport"),
      dataIndex: "u_typeOfTransport",
      key: "u_typeOfTransport",
      width: 150,
      render: (text, record) => {
        return text || "-";
      },
      ...getColumnSearchProps("u_typeOfTransport"),
    },
    {
      title: t("numberOfTransport"),
      dataIndex: "tansportNumber",
      key: "tansportNumber",
      width: 150,
      render: (text, record) => {
        return text || "-";
      },
      ...getColumnSearchProps("tansportNumber"),
    },
    {
      title: t("directionType"),
      dataIndex: "u_DirectionType",
      key: "u_DirectionType",
      width: 150,
      filterDropdown: (text, record) => {
        return (
          <div style={{ padding: 8 }}>
            <Select
              value={directionType}
              onChange={(value) => {
                setDirectionType(value);
              }}
              style={{ width: 188, marginBottom: 8, display: "block" }}
              options={[
                {
                  label: t("all"),
                  value: "",
                },
                {
                  label: t("transit"),
                  value: "transit",
                },
                {
                  label: t("direct"),
                  value: "direct",
                },
              ]}
            />
          </div>
        );
      },

      render: (text, record) => {
        return (
          (text && text === "transit" ? (
            <Tag color="blue">{t("transit")}</Tag>
          ) : text === "direct" ? (
            <Tag color="green">{t("direct")}</Tag>
          ) : (
            text
          )) || "-"
        );
      },
    },
    {
      title: t("partyNumber"),
      dataIndex: "u_PartyNumber",
      key: "u_PartyNumber",
      width: 150,
      render: (text, record) => {
        return t(text) || "-";
      },
      ...getColumnSearchProps("u_PartyNumber"),
    },
  ];

  const refetchTable = () => {
    setCurrentPage(0);
    fetchData(
      selectedCardName,
      selectedDocNum,
      startDate,
      endDate,
      typeOfTransport,
      numberOfTransport,
      partyNumber,
      directionType,
      currentPage,
      currentPage > 0,
    );
  };

  return (
    <div className="flex h-full w-full overflow-y-auto">
      <Menubar />
      <HeaderWithInfinityScroll
        title={"purchase-delivery"}
        currentPage={currentPage + 1}
        handlePreviousPage={handlePreviousPage}
        handleNextPage={handleNextPage}
        hasMoreData={hasMoreData}
        columns={columns}
        fdata={dataSource}
        loading={loading}
        onStartDateChange={handleStartDate}
        onEndDateChange={handleEndDate}
        startDate={startDate}
        endDate={endDate}
        formattedDate={moment().format("YYYY-MM-DD")}
        handleRowClick={handleRowClick}
        total={total}
      />

      <DirectModal
        visible={isDirectModalVisible}
        data={selectedRowData}
        onClose={() => setIsDirectModalVisible(false)}
        refetch={refetchTable}
      />

      <TransportModal
        visible={isTransportModalVisible}
        data={selectedRowData}
        onClose={() => setIsTransportModalVisible(false)}
        refetch={refetchTable}
      />
    </div>
  );
};

export default PurchaseDelivery;
