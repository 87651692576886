import React, { useState } from "react";
import { Space } from "antd";
import DriversAndEmployees from "./DriversAndEmployees";
import Xarajatlar from "./Xarajatlar";
import Menubar from "../../../../components/WhrManager/App";
import Navbar from "../../../../components/navbar";
import { useTranslation } from "react-i18next";
const OutgoingPayments = () => {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState("tab1");

  const renderContent = () => {
    switch (activeTab) {
      case "tab1":
        return <DriversAndEmployees />;
      case "tab2":
        return <Xarajatlar />;

      default:
        return null;
    }
  };

  return (
    <div className="flex h-full w-full overflow-y-auto">
      <Menubar />
      <div className="h-screen w-full overflow-y-auto">
        <Navbar />
        {/* <div className="flex rounded-lg bg-gray-50 p-1">
          <button
            onClick={() => setActiveTab("chat")}
            className={`duration-200", activeTab === "chat" ? "bg-white shadow-sm" : "text-gray-500 hover:text-blue-600" flex-1 rounded-md px-4 py-2 text-sm font-medium text-blue-600 transition-all`}
          >
            Chat
          </button>
          <button
            className={`duration-200", activeTab === "history" ? "bg-white shadow-sm" : "text-gray-500 hover:text-blue-600", flex-1 rounded-md px-4 py-2 text-sm font-medium text-blue-600 transition-all`}
            onClick={() => setActiveTab("tab1")}
          >
            {t("driversAndEmployees")}
          </button>
        </div> */}

        <Space
          style={{ marginBottom: 16 }}
          className="m-5 mt-10 flex w-max rounded-lg bg-gray-200 p-1"
        >
          <button
            className={`duration-200", ${activeTab === "tab1" ? "bg-sky-700 text-blue-200 shadow-sm" : "text-black hover:text-blue-600"} flex-1 rounded-md px-4 py-2 text-sm font-medium transition-all`}
            onClick={() => setActiveTab("tab1")}
          >
            {t("driversAndEmployees")}
          </button>
          <button
            className={`duration-200", ${activeTab === "tab2" ? "bg-sky-700 text-blue-200 shadow-sm" : "text-black hover:text-blue-600"} flex-1 rounded-md px-4 py-2 text-sm font-medium transition-all`}
            onClick={() => setActiveTab("tab2")}
          >
            {t("expenses")}
          </button>
        </Space>
        <div>{renderContent()}</div>
      </div>
    </div>
  );
};

export default OutgoingPayments;
