import { useRef, useCallback, useEffect } from "react";
import Navbar from "./navbar";
import { useTranslation } from "react-i18next";
import { Table, Input } from "antd";
import { useRouterPush } from "../hooks/use-router-push";
import { formatMoney } from "../utils/formatMoney";
import debounce from "lodash/debounce";

function HeaderWithInfinityScroll({
  title,
  currentPage,
  handleNextPage,
  hasMoreData,
  columns,
  fdata,
  loading,
  handleRowClick,
  onBtnClick,
  onStartDateChange,
  onEndDateChange,
  startDate,
  endDate,
  formattedDate,
  total,
  filtersNode,
  scrollX = 900,
  isAct = false,
  lastBalance,
}) {
  const { t } = useTranslation();
  const { push } = useRouterPush();
  const tableContainerRef = useRef(null);

  // Debounced scroll handler
  const handleScroll = useCallback(
    debounce(() => {
      if (!tableContainerRef.current || !hasMoreData || loading) {
        return;
      }

      const container = tableContainerRef.current;
      const { scrollTop, scrollHeight, clientHeight } = container;

      // If we're near the bottom (within 200px), load more data
      if (scrollHeight - scrollTop - clientHeight < 100) {
        handleNextPage();
      }
    }, 100),
    [hasMoreData, handleNextPage, loading],
  );

  // Add scroll event listener
  useEffect(() => {
    const container = tableContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      return () => container.removeEventListener("scroll", handleScroll);
    }
  }, [handleScroll]);

  const addBtnClick = () => {
    push({ query: { add: true } }, { update: true });
  };

  return (
    <div className="h-screen w-full overflow-y-auto">
      <Navbar />
      <h1 className="font-poppins ml-4 mt-10 text-xl font-bold text-black sm:ml-5 sm:mt-14 sm:text-2xl">
        {t(title)}
      </h1>
      <div className="mt-10 w-full border-[1px] border-[#E8E8E8] sm:mt-14"></div>

      <div className="mt-6 flex w-full items-center justify-between gap-4 px-4 sm:mt-10 sm:gap-7 sm:px-10">
        <div className="ml-5 mt-5 flex gap-5">
          {filtersNode}
          {onEndDateChange && (
            <>
              <div className="flex flex-col gap-3">
                <p>{t("startDate")}</p>
                <Input
                  type="date"
                  value={startDate}
                  className="w-[150px]"
                  onChange={onStartDateChange}
                  max={endDate}
                />
              </div>
              <div className="flex flex-col gap-3">
                <p>{t("endDate")}</p>
                <Input
                  type="date"
                  value={endDate}
                  className="w-[150px]"
                  onChange={onEndDateChange}
                  min={startDate}
                  max={formattedDate}
                />
              </div>
            </>
          )}
        </div>
        {onBtnClick && (
          <div className="mt-6 flex w-full items-center justify-end gap-4 px-4 sm:mt-10 sm:gap-7 sm:px-10">
            <button
              onClick={() => {
                onBtnClick();
                addBtnClick();
              }}
              className="h-[40px] w-[150px] rounded-2xl bg-[#0A4D68] text-white sm:w-[200px]"
            >
              {t("Add")}
            </button>
          </div>
        )}
      </div>
      <div className="ml-4 mt-6 sm:ml-5 sm:mt-10">
        {/* Current page indicator */}
        {/* <div className="font-nunitto mb-4 font-bold">
          {t("page")} : {currentPage}
        </div> */}

        <div className="mt-10">
          {isAct && (
            <>
              <p>{t("LastBalanceFC")}:</p>
              <span className="font-bold"> {formatMoney(lastBalance)}</span>
            </>
          )}
          <div
            className="mt-5 max-h-[calc(100vh-100px)] overflow-x-auto"
            ref={tableContainerRef}
          >
            <Table
              columns={columns}
              dataSource={fdata}
              pagination={false}
              className="w-full"
              // rowKey={(record) => record.docEntry}
              sticky={true}
              scroll={{ x: scrollX, scrollToFirstRowOnChange: false }}
              loading={loading}
              {...(handleRowClick && {
                onRow: (record, index) => ({
                  onClick: () => {
                    handleRowClick(record, index);
                    push(
                      { query: { edit: true, id: record.docEntry } },
                      { replace: true },
                    );
                  },
                }),
              })}
              {...(total && {
                summary: () => (
                  <Table.Summary fixed="bottom">
                    <Table.Summary.Row>
                      <Table.Summary.Cell
                        index={0}
                        className="font-bold text-black"
                      ></Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={1}
                        colSpan={1}
                        className="font-bold text-black"
                      >
                        {t("total")}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={2}
                        className="font-bold text-black"
                      ></Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={3}
                        className="font-bold text-black"
                      >
                        {new Intl.NumberFormat("fr-FR").format(total)} USD
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={4}
                        colSpan={1}
                        className="font-bold text-black"
                      ></Table.Summary.Cell>
                    </Table.Summary.Row>
                  </Table.Summary>
                ),
              })}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderWithInfinityScroll;
