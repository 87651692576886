import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Select,
  Spin,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { disabledDate, getFiveDaysAgo, getToday } from "../../../utils/getDays";
import { http } from "../../../services/http";
import { useSearchParams } from "react-router-dom";
import { useRouterPush } from "../../../hooks/use-router-push";
import { useLocationParams } from "../../../hooks/use-location-params";
import moment from "moment";
import dayjs from "dayjs";
import {
  formatMoney,
  handleInputChangeMoney,
  removeSpaces,
} from "../../../utils/formatMoney";
import debounce from "lodash.debounce";

const CreatePaymentAccount = ({ refetchTable }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { push } = useRouterPush();
  const [loading, setLoading] = useState(false);
  const [partnersLoading, setPartnersLoading] = useState(false);
  const [rateLoading, setRateLoading] = useState(false);
  const [partnersData, setPartnersData] = useState([]);
  const [docCurrency, setDocCurrency] = useState("USD");
  const { query } = useLocationParams();
  const [skip, setSkip] = useState(0);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [docRate, setDocRate] = useState(0);
  const [docRateApi, setDocRateApi] = useState(0);

  const refreshAccountSelectOptions = () => {
    setPartnersData([]);
    setSkip(0);
    setHasMoreData(true);
    setDocCurrency("USD");
  };

  useEffect(() => {
    if (!query.add || !query.edit) {
      refreshAccountSelectOptions();
    }
  }, [query.add, query.edit]);

  const fetchGroup = async (newSkip, searchValue = "") => {
    if (partnersLoading || !hasMoreData) return;
    try {
      setPartnersLoading(true);
      const { data } = await http.get(
        `api/cashier/chart-of-accounts?skip=${newSkip * 20 || 0}&name=${searchValue}&currencyType=${docCurrency}`,
      );
      if (data.data.length < 20) {
        setHasMoreData(false);
      }
      const mappedItems = data.data.map((item) => ({
        label: item.name + " " + item.code,
        value: item.code,
      }));
      setPartnersData((prevData) => [...prevData, ...mappedItems]);
    } catch (err) {
      console.log(err);
    } finally {
      setPartnersLoading(false);
    }
  };

  const handleScroll = (e) => {
    const { target } = e;
    if (target.scrollTop + target.offsetHeight >= target.scrollHeight - 10) {
      setSkip((prevSkip) => {
        const newSkip = prevSkip + 1;
        fetchGroup(newSkip, searchValue); // Scroll uchun oxirgi searchValue yuboriladi
        return newSkip;
      });
    }
  };

  const getCurrencyRate = async () => {
    try {
      setRateLoading(true);
      const { data } = await http.get(
        `api/cashier/currency-rate?Currency=UZS&Date=${form.getFieldValue("docDate")}`,
      );
      setDocRateApi(Number(data.data));
      setDocRate(Number(data.data));
      form.setFieldsValue({ docRate: Number(data.data) });
    } catch (err) {
      console.log(err);
    } finally {
      setRateLoading(false);
    }
  };

  useEffect(() => {
    if (query.add) {
      getCurrencyRate();
      form.setFieldsValue({
        docDate: dayjs(),
      });
    }
  }, [query.add]);

  useEffect(() => {
    fetchGroup();
  }, [docCurrency]);

  const postData = async (values) => {
    try {
      setLoading(true);
      await http.post("api/cashier/incoming-payment", {
        ...values,
        docDate: dayjs(values.docDate).format("YYYY-MM-DD"),
        docType: "rAccount",
        cashSum: removeSpaces(values.cashSum),
        docRate: removeSpaces(docRate),
      });
      refetchTable();
      message.success({ content: t("sucCreated") });
      onClose();
    } catch (err) {
      message.error({ content: JSON.stringify(err) });
    } finally {
      setLoading(false);
    }
  };

  const getSingleData = async () => {
    try {
      setLoading(true);
      const data = await http.get(`api/cashier/incoming-payment/${query.id}`);
      form.setFieldsValue({
        ...data?.data?.data,
        cashSum:
          data?.data?.data.docCurrency === "USD"
            ? data?.data?.data.cashSum
            : data?.data?.data.cashSumFC,
        docDate: dayjs(data?.data?.data.docDate),
      });
    } catch (err) {
      message.error({ content: JSON.stringify(err) });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (query.edit && query.id) {
      getSingleData();
    }
  }, [query.id, query.edit]);

  const onClose = () => {
    push(
      { query: { add: undefined, edit: undefined, id: undefined } },
      { update: true },
    );
    form.resetFields();
  };

  const handleSearch = useCallback(
    debounce((value) => {
      setSearchValue(value);
      setHasMoreData(true); // Filterlashda yuklashni yana yoqish
      setPartnersData([]);
      fetchGroup(0, value); // Filter so'rovini yuborish
    }, 300),
    [], // Debounce intervali (300ms)
  );

  return (
    <Modal
      open={!!query.add || !!query.edit}
      onCancel={onClose}
      footer={null}
      closeIcon={<CloseOutlined />}
      width="80%"
      style={{ height: "80%" }}
    >
      <Spin spinning={rateLoading || loading}>
        <div className="w-full sm:px-14">
          <Form
            form={form}
            layout={"vertical"}
            onFinish={postData}
            initialValues={{
              docCurrency: "USD",
              docDate: dayjs(),
            }}
          >
            <div className="mt-10 grid gap-3 sm:grid-cols-1 md:grid-cols-3">
              <Form.Item name="docDate" label={t("date")}>
                <DatePicker
                  format="DD.MM.YYYY"
                  onChange={getCurrencyRate}
                  disabledDate={disabledDate}
                  className="h-12 w-full font-bold"
                />
              </Form.Item>
              <Form.Item name="accountCode" label={t("Hisob raqamlar")}>
                <Select
                  showSearch
                  className="h-12 font-normal"
                  options={[
                    ...partnersData,
                    ...(partnersLoading
                      ? [
                          {
                            label: <Spin size="small" tip="Loading..." />,
                            value: "loading",
                          },
                        ]
                      : []),
                  ]}
                  loading={partnersLoading}
                  onSearch={handleSearch} // Yozilgan so'zlar uchun
                  onPopupScroll={handleScroll} // Scroll uchun
                  virtual={true}
                  notFoundContent={
                    partnersLoading ? <Spin size="small" /> : null
                  } // Yuklanayotgan spinner
                  placeholder={t("search")}
                  filterOption={false} // Ant Designning ichki filtratsiyasini o'chirish
                />
              </Form.Item>
              <Form.Item
                name="docRate"
                label={t("Dollar kursi")}
                rules={[
                  {
                    required: true,
                    message: t("required"),
                  },

                  {
                    validator: (_, value) => {
                      if (
                        !value ||
                        (value >= docRateApi - 50 && value <= docRateApi + 50)
                      ) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          t("range_error", {
                            min: docRateApi - 50,
                            max: docRateApi + 50,
                          }),
                        ),
                      );
                    },
                  },
                ]}
              >
                <Input
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  value={docRate}
                  onChange={(e) => setDocRate(e.target.value)}
                  className="h-12"
                />
              </Form.Item>
              <Form.Item name="cashSum" label={t("Cash sum")}>
                <Input
                  className="h-12"
                  onChange={(e) => handleInputChangeMoney(e, form, "cashSum")}
                />
              </Form.Item>
              <Form.Item name="docCurrency" label={t("currency")}>
                <Select
                  onChange={(value) => {
                    refreshAccountSelectOptions();
                    setDocCurrency(value);
                  }}
                  showSearch
                  className="h-12"
                  options={[
                    { label: t("USD"), value: "USD" },
                    { label: t("UZS"), value: "UZS" },
                  ]}
                />
              </Form.Item>
              <Form.Item name="remarks" label={t("comments")}>
                <Input.TextArea rows={4} />
              </Form.Item>
            </div>
            <div className="mt-24 flex justify-center">
              {!query.edit && (
                <Button
                  type={"primary"}
                  htmlType={"submit"}
                  className="h-12 w-full rounded-3xl bg-[#0A4D68] text-white sm:w-[350px]"
                  disabled={
                    !(
                      docRate >= docRateApi - 50 && docRate <= docRateApi + 50
                    ) || loading
                  }
                  loading={loading}
                >
                  {t("create")}
                </Button>
              )}
            </div>
          </Form>
        </div>
      </Spin>
    </Modal>
  );
};

export default CreatePaymentAccount;
