import React, { useState, useEffect } from "react";
import { Modal, Input, Table, Select, Tag } from "antd";
import { useTranslation } from "react-i18next";
import { CloseOutlined } from "@ant-design/icons";
import moment from "moment";
import { http } from "../../services/http";

const EmployeesModal = ({ visible, data, onClose }) => {
  const { t } = useTranslation();

  const [employeesData, setEmployeesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [docNum, setDocNum] = useState("");

  useEffect(() => {
    if (data) {
      fetchByDocEntry(data.u_DeliveryID);
    }
  }, [data, visible, data]);

  const fetchByDocEntry = async (docEntry) => {
    setLoading(true);
    try {
      const { data } = await http.get(
        `api/deliverynotesdata/get-employees?docEntry=${docEntry}`,
      );

      setEmployeesData(data[0]?.documentLines);
      setDocNum(data[0]?.docNum);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  if (!data) return null;

  const columns = [
    {
      title: t("code"),
      dataIndex: "employeeID",
      key: "employeeID",
    },
    {
      title: t("name"),
      dataIndex: "employeeName",
      key: "employeeName",
    },
  ];

  const tableData = employeesData;

  return (
    <Modal
      title={
        <h1 className="font-nunito text-xl font-extrabold text-[#000000]">
          {t("order")} № {docNum}
        </h1>
      }
      visible={visible}
      onCancel={onClose}
      loading={loading}
      footer={null}
      closeIcon={<CloseOutlined />}
      width="90%"
      style={{ height: "70%" }}
      bodyStyle={{ height: "calc(100% - 55px)", overflowY: "auto" }}
    >
      <div className="w-full px-4 sm:px-14">
        <Table
          columns={columns}
          dataSource={tableData}
          loading={loading}
          pagination={false}
          className="border-1 mt-12 border-black"
        />
      </div>
    </Modal>
  );
};

export default EmployeesModal;
