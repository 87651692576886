import React, { useState, useEffect } from "react";
import { Select, Input, message, Spin, Button } from "antd";
import { http } from "../../../services/http";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getToday, getFiveDaysAgo } from "../../../utils/getDays";

const { Option } = Select;
function CreateSale({ onClose, visible }) {
  const { t } = useTranslation();
  const employeeId = useSelector((state) => state.main.employeeId);
  const today = getToday();
  const fiveDaysAgo = getFiveDaysAgo();
  const [loading, setLoading] = useState(false);
  const [currency, setCurrency] = useState();
  const [date, setDate] = useState(today);

  const [loadingData, setLoadingData] = useState({
    fdata: false,
    pdata: false,
    wdata: false,
  });
  const [data, setData] = useState({
    fdata: [],
    pdata: [],
    cardCode: "",
    salesPersonCode: "",
  });
  const [items, setItems] = useState([
    { itemCode: "", quantity: "", unitPrice: "", selectedPrice: null },
  ]);

  const { fdata, pdata, cardCode, salesPersonCode } = data;

  const [wdata, setwData] = useState([]);

  const fetchGroup = async () => {
    try {
      const { data } = await http.get(`api/salesemployees`);
      const mappedItems = data.map((item) => ({
        code: item.slpCode,
        name: item.slpName,
      }));
      setwData(mappedItems);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchGroup();
  }, []);

  useEffect(() => {
    if (visible) {
      const fetchAllData = async () => {
        setLoadingData({ fdata: true, pdata: true });
        await Promise.all([fetchData(), fetchPartner(), fetchGroup()]);
        setLoadingData({ fdata: false, pdata: false });
      };

      const fetchData = async () => {
        try {
          const { data } = await http.get(`api/items`);
          const mappedItems = data.map((item) => ({
            code: item.itemCode,
            name: item.itemName,
            price: item.itemPrice.price,
            priceList: item.itemPrice.priceList,
          }));
          setData((prevState) => ({ ...prevState, fdata: mappedItems }));
        } catch (error) {
          message.error(t("fetchError"));
          console.log(error);
        }
      };

      const fetchPartner = async () => {
        try {
          const { data } = await http.get(`api/businesspartners/mijozlar`);
          const mappedItems = data.map((item) => ({
            code: item.cardCode,
            name: item.cardName,
          }));
          setData((prevState) => ({ ...prevState, pdata: mappedItems }));
        } catch (err) {
          message.error(t("fetchError"));
          console.log(err);
        }
      };
      fetchAllData();
      resetForm();
    }
  }, [t, visible]);

  const handleSelectChange = (index, value) => {
    const selectedItem = fdata.find((item) => item.name === value);
    const newItems = [...items];
    newItems[index] = {
      ...newItems[index],
      itemCode: selectedItem.code,
      unitPrice: selectedItem.price,
    };
    setItems(newItems);
  };

  const handleCurrencyChange = (value) => {
    setCurrency(value);
    message.info(t("inputPrice", { value }));
    const newItems = items.map((item) => ({
      ...item,
      unitPrice: "",
      selectedPrice: "",
    }));

    setItems(newItems);
  };

  const handleMijozChange = (value) => {
    const selectedItem = pdata.find((item) => item.name === value);
    setData((prevState) => ({ ...prevState, cardCode: selectedItem.code }));
  };

  const handleGroupChange = (value) => {
    const selectedItem = wdata.find((item) => item.name === value);
    setData((prevState) => ({
      ...prevState,
      salesPersonCode: selectedItem.code,
    }));
  };

  const handleQuantityChange = (index, value) => {
    const newItems = [...items];
    newItems[index] = { ...newItems[index], quantity: value };
    setItems(newItems);
  };

  const handlePriceChange = (index, value) => {
    const newItems = [...items];
    newItems[index] = {
      ...newItems[index],
      unitPrice: value,
      selectedPrice: value,
    };
    setItems(newItems);
  };

  const addItem = () => {
    setItems([
      ...items,
      {
        itemCode: "",
        quantity: "",
        unitPrice: "",
        currency: "",
        selectedPrice: null,
      },
    ]);
  };

  const removeItem = (index) => {
    const updatedItems = items.filter((_, i) => i !== index);
    setItems(updatedItems);
  };

  const postData = async () => {
    if (
      cardCode &&
      employeeId &&
      items.every((item) => item.itemCode && item.quantity > 0)
    ) {
      setLoading(true);
      try {
        const documentLines = items.map((item, index) => ({
          lineNum: index,
          itemCode: item.itemCode,
          quantity: item.quantity,
          unitPrice: item.unitPrice,
          currency: currency,
        }));

        await http.post("api/salesorder", {
          cardCode,
          docDueDate: today,
          docDate: date,
          salesPersonCode,
          documentsOwner: employeeId,
          docCurrency: currency,
          documentLines,
        });
        message.success(t("sucCreated"));
        setTimeout(() => {
          resetForm();
          onClose();
        }, 800);
      } catch (error) {
        message.error(t("errCreation") + error.message);
      } finally {
        setLoading(false);
      }
    } else {
      message.error(t("fillForm"));
    }
  };

  const resetForm = () => {
    setItems([
      {
        itemCode: "",
        quantity: "",
        unitPrice: "",
        currency: "",
        selectedPrice: null,
      },
    ]);
    setData((prevState) => ({ ...prevState, cardCode: "" }));
    setCurrency("");
  };

  return (
    <div className="w-full sm:px-14">
      <div className="mr-5 w-full">
        <div className="mt-10 flex flex-col items-center justify-between sm:flex-row">
          <div className="mb-4 flex flex-col gap-3 sm:mb-0">
            <p className="font-nunito">{t("mijoz")}</p>
            <Spin spinning={loadingData.pdata}>
              <Select
                value={
                  cardCode
                    ? pdata.find((item) => item.code === cardCode)?.name
                    : undefined
                }
                placeholder={t("choose")}
                onChange={handleMijozChange}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                className="flex h-12 w-full items-center justify-center sm:w-[250px]"
              >
                {pdata
                  .filter((item) => item.name !== null)
                  .map((item) => (
                    <Select.Option key={item.code} value={item.name}>
                      {item.name}
                    </Select.Option>
                  ))}
              </Select>
            </Spin>
          </div>

          <div className="flex flex-col gap-3">
            <p className="font-nunito">{t("date")}</p>
            <Input
              type="date"
              value={date}
              className="flex w-full items-center justify-center font-bold text-black sm:w-[120px]"
              onChange={(e) => {
                setDate(e.target.value);
              }}
              max={today}
              min={fiveDaysAgo}
            />
          </div>

          <div className="flex w-full flex-col gap-3 sm:w-auto">
            <p className="font-nunito">{t("currency")}</p>
            <Select
              placeholder={t("choose")}
              showSearch
              optionFilterProp="children"
              onChange={handleCurrencyChange}
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              className="flex h-12 w-full items-center justify-center sm:w-[250px]"
            >
              <Option key="USD" value="USD">
                USD
              </Option>
              <Option key="UZS" value="UZS">
                UZS
              </Option>
            </Select>
          </div>
        </div>

        <div className="mt-10 flex flex-col items-end gap-5 sm:flex-row">
          <div className="flex w-max flex-col gap-3">
            {items.map((item, index) => (
              <div
                key={index}
                className="mt-1 flex w-max flex-col items-center gap-5 sm:flex-row"
              >
                <div className="flex w-full flex-col gap-3 sm:w-auto">
                  {index === 0 ? <p>{t("item")}</p> : ""}
                  <Spin spinning={loadingData.fdata}>
                    <Select
                      value={
                        item.itemCode
                          ? fdata.find((fitem) => fitem.code === item.itemCode)
                              ?.name
                          : undefined
                      }
                      placeholder={t("choose")}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      className="flex h-12 w-full items-center justify-center sm:w-[350px]"
                      onChange={(value) => handleSelectChange(index, value)}
                    >
                      {fdata
                        .filter((fitem) => fitem.name !== null)
                        .map((fitem) => (
                          <Select.Option key={fitem.code} value={fitem.name}>
                            {fitem.name}
                          </Select.Option>
                        ))}
                    </Select>
                  </Spin>
                </div>

                <div className="flex w-full flex-col gap-3 sm:w-auto">
                  {index === 0 ? <p>{t("price")}</p> : ""}
                  <Input
                    type="number"
                    value={item.selectedPrice || ""}
                    className="flex h-12 w-full items-center justify-center border-[1px] border-[#D0D0D0] sm:w-[120px]"
                    onChange={(e) => handlePriceChange(index, e.target.value)}
                  />
                </div>

                <div className="flex w-full flex-col gap-3 sm:w-auto">
                  {index === 0 ? <p>{t("quantity")}</p> : ""}
                  <Input
                    value={item.quantity}
                    onChange={(e) =>
                      handleQuantityChange(index, e.target.value)
                    }
                    type="number"
                    className="flex h-12 w-full items-center justify-center border-[1px] border-[#D0D0D0] sm:w-[120px]"
                  />
                </div>

                <div className="flex w-full flex-col gap-3 sm:w-auto">
                  {index === 0 ? <p>{t("totalPrice")}</p> : ""}
                  <Input
                    value={Number(item.quantity) * Number(item.selectedPrice)}
                    disabled
                    style={{ color: "black" }}
                    className="flex h-12 w-full items-center justify-center border-[1px] border-[#D0D0D0] sm:w-[120px]"
                  />
                </div>
                {items.length > 1 && (
                  <button
                    onClick={() => removeItem(index)}
                    className={`mt-2 h-7 w-[30px] border-2 border-black bg-[#0A4D68] text-red-500 ${index === 0 ? "sm:mt-7" : "sm:mt-1"}`}
                  >
                    -
                  </button>
                )}
              </div>
            ))}
          </div>

          <div>
            <button
              onClick={addItem}
              className="mb-2 h-7 w-[30px] border-2 border-black bg-[#0A4D68] text-white"
            >
              +
            </button>
          </div>
        </div>

        <div className="mt-10 w-full sm:w-auto">
          <p className="font-nunito">{t("responsiblePerson")}</p>
          <Select
            value={
              salesPersonCode
                ? wdata.find((item) => item.code === salesPersonCode)?.name
                : undefined
            }
            placeholder={t("choose")}
            showSearch
            optionFilterProp="children"
            onChange={handleGroupChange}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            className="flex h-12 w-full items-center justify-center sm:w-[250px]"
          >
            {wdata
              .filter((item) => item.name !== null)
              .map((item) => (
                <Select.Option key={item.code} value={item.name}>
                  {item.name}
                </Select.Option>
              ))}
          </Select>
        </div>

        <div className="mt-24 flex justify-center">
          <Button
            onClick={postData}
            className="h-12 w-full rounded-3xl bg-[#0A4D68] text-white sm:w-[350px]"
            disabled={loading}
            loading={loading}
          >
            {loading ? t("saving") : t("saveAndCreate")}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default CreateSale;
