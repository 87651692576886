import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { Input, Select, Table } from "antd";
import Menubar from "../../../components/WhrManager/App";
import { http } from "../../../services/http";
import { SearchOutlined } from "@ant-design/icons";
import debounce from "lodash.debounce";
import Highlighter from "react-highlight-words";
import { useTranslation } from "react-i18next";
import { columnFinishedSales } from "../../../utils/columns";
import FinishedSalesModalWrh from "../../../components/WhrManager/finishedSalesModal";
import moment from "moment";
import { get } from "lodash";
import Navbar from "../../../components/navbar";

const today = new Date();
const day = ("0" + today.getDate()).slice(-2);
const month = ("0" + (today.getMonth() + 1)).slice(-2);
const year = today.getFullYear();
const formattedDate = `${year}-${month}-${day}`;

const FinishedSales = () => {
  const { t } = useTranslation();
  const tableContainerRef = useRef(null);
  const isLoadingRef = useRef(false);

  const [fdata, setFData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [docNum, setDocNum] = useState("");
  const [cardName, setCardName] = useState("");
  const [slpName, setSlpName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState(formattedDate);
  const [total, setTotal] = useState(0);
  const [ordersStatus, setOrdersStatus] = useState("");
  const [data, setData] = useState([]);
  const [docStatus, setDocStatus] = useState("");
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const orderStatus = [
    { label: "Barchasi", value: "" },
    { label: "Tasdiqlandi", value: "Tasdiqlandi" },
    { label: "Mas'ulga biriktirildi", value: "Biriktirildi" },
    { label: "Buyurtma yuklandi", value: "BuyurtmaYuklandi" },
    { label: "Yuklash tasdiqlandi", value: "YuklashTasdiqlandi" },
  ];

  const fetchData = useCallback(
    async (
      page,
      cardName,
      SdocNum,
      SslpName,
      SstartDate,
      SendDate,
      docStatus,
      orderStatus,
      isLoadMore = false,
    ) => {
      setLoading(true);
      if (isLoadingRef.current) return;

      try {
        isLoadingRef.current = true;
        if (!isLoadMore) {
          setLoading(true);
        } else {
          setIsLoadingMore(true);
        }
        let url = "";
        url = `api/yakunlangansotuvlar?cardName=${cardName}&orderStatus=${orderStatus}&docNum=${SdocNum}&slpName=${SslpName}&docStatus=${docStatus}&startDate=${SstartDate}&endDate=${SendDate}&pageToken=${page}&pageSize=30`;

        const { data } = await http.get(url);
        console.log(data);
        const formattedData = data.docs;
        if (page === 1) {
          setFData(formattedData);
        } else {
          setFData((prevData) => [...prevData, ...formattedData]);
        }

        setHasMoreData(formattedData.length === 30);
        setTotal(get(data, "total", 0));
      } catch (error) {
        console.log(error);
        setHasMoreData(false);
      } finally {
        setLoading(false);
        setIsLoadingMore(false);
        isLoadingRef.current = false;
      }
    },
    [cardName, docNum, startDate, endDate, slpName, docStatus, ordersStatus],
  );

  useEffect(() => {
    fetchData(
      currentPage,
      cardName,
      docNum,
      slpName,
      startDate,
      endDate,
      docStatus,
      ordersStatus,
      currentPage > 1,
    );
  }, [
    fetchData,
    currentPage,
    cardName,
    docNum,
    slpName,
    startDate,
    endDate,
    docStatus,
    ordersStatus,
  ]);

  const handleRowClick = (record) => {
    setSelectedRowData(record);
    setIsModalVisible(true);
  };

  let searchInput = null;

  const handleStartDate = (e) => {
    setStartDate(e.target.value);
    setFData([]);
    setCurrentPage(1);
  };

  const handleEndDate = (e) => {
    setEndDate(e.target.value);
    setFData([]);
    setCurrentPage(1);
  };

  useEffect(() => {
    fetchResponsibleManagers();
  }, []);

  const debouncedSetPage = useMemo(
    () =>
      debounce(() => {
        if (!isLoadingRef.current) {
          setCurrentPage((prev) => prev + 1);
        }
      }, 300),
    [],
  );

  const handleScroll = useCallback(
    (e) => {
      if (!hasMoreData || loading || isLoadingMore || isLoadingRef.current)
        return;

      const { scrollTop, scrollHeight, clientHeight } = e.target;

      if (scrollHeight - scrollTop <= clientHeight + 50) {
        debouncedSetPage();
      }
    },
    [hasMoreData, loading, isLoadingMore, debouncedSetPage],
  );

  useEffect(() => {
    const tableBody =
      tableContainerRef.current?.getElementsByClassName("ant-table-body")[0];
    if (tableBody) {
      tableBody.addEventListener("scroll", handleScroll);
      return () => tableBody.removeEventListener("scroll", handleScroll);
    }
  }, [handleScroll]);

  const fetchResponsibleManagers = async () => {
    try {
      const { data } = await http.get(
        `api/sales/warehouse-employee-info?skip=0`,
      );

      setData(data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const debouncedSearch = useMemo(
    () =>
      debounce((value, dataIndex, confirm) => {
        if (dataIndex === "orderStatus") {
          if (value) {
            setOrdersStatus(value);
          } else {
            setOrdersStatus("");
          }
        } else if (dataIndex === "cardName") {
          if (value) {
            setCardName(value);
          } else {
            setCardName("");
          }
        } else if (dataIndex === "docStatus") {
          if (value) {
            setDocStatus(value);
          } else {
            setDocStatus("");
          }
        } else if (dataIndex === "docNum") {
          if (value) {
            setDocNum(value);
          } else {
            setDocNum("");
          }
        } else if (dataIndex === "yaratdi") {
          if (value) {
            setSlpName(value.toUpperCase());
          } else {
            setSlpName("");
          }
        } else if (dataIndex === "sana") {
          if (value) {
            const formattedStartDate = new Date(value[0]).toLocaleString();
            const formattedEndDate = new Date(value[1]).toLocaleString();

            if (formattedStartDate && formattedEndDate) {
              setStartDate(moment(formattedStartDate).format("YYYY-MM-DD"));
              setEndDate(moment(formattedEndDate).format("YYYY-MM-DD"));
            }
          } else {
            setStartDate("");
            setEndDate("");
          }
        } else {
        }
        setFData([]);
        setCurrentPage(1);
        confirm();
      }, 3000),
    [fetchData, currentPage],
  );
  const getColumnSearchProps = (dataIndex) => {
    if (dataIndex === "orderStatus") {
      return {
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
          <div style={{ padding: 8 }}>
            <Select
              value={selectedKeys[0] || ""}
              onChange={(value) => {
                setSelectedKeys(value ? [value] : [""]);
                debouncedSearch(value, dataIndex, confirm);
              }}
              style={{ width: 188, marginBottom: 8, display: "block" }}
              options={orderStatus.map((item) => {
                return {
                  value: item.value,
                  label: item.label,
                };
              })}
            />
          </div>
        ),
        filterIcon: (filtered) => (
          <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
        ),
        onFilter: (value, record) => {
          if (value === "") {
            return true;
          }
          return record[dataIndex]
            ? record[dataIndex].toString().toLowerCase() === value.toLowerCase()
            : "";
        },
        render: (text) => text,
      };
    }

    if (dataIndex === "docStatus") {
      return {
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
          <div style={{ padding: 8 }}>
            <Select
              value={selectedKeys[0] || ""}
              onChange={(value) => {
                setSelectedKeys(value ? [value] : [""]);
                debouncedSearch(value, dataIndex, confirm);
              }}
              style={{ width: 188, marginBottom: 8, display: "block" }}
              options={[
                {
                  value: "",
                  label: t("all"),
                },
                {
                  value: "O",
                  label: t("open"),
                },
                {
                  value: "C",
                  label: t("closed"),
                },
              ]}
            />
          </div>
        ),
        filterIcon: (filtered) => (
          <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
        ),
        onFilter: (value, record) => {
          if (value === "") {
            return true;
          }
          return record[dataIndex]
            ? record[dataIndex].toString().toLowerCase() === value.toLowerCase()
            : "";
        },
        render: (text) => text,
      };
    }
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`${t("search")} ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              handleSearch(e, confirm, dataIndex);
            }}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          ? record[dataIndex]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : "",
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.select(), 100);
        }
      },
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        ) : (
          text
        ),
    };
  };
  const handleSearch = (e, confirm, dataIndex) => {
    const value = e.target.value;
    debouncedSearch(value, dataIndex, confirm);
  };

  const columns = columnFinishedSales(t, getColumnSearchProps).map((col) => {
    if (col.key === "responsiblePerson") {
      return {
        ...col,
        render: (record) => {
          const responsible = record.responsiblePerson
            ? data.find((item) => {
                return item.employeeId === Number(record.responsiblePerson);
              })
            : "-";

          return (
            <span>
              {`${responsible?.firstName ? responsible?.firstName : ""} ${responsible?.lastName ? responsible?.lastName : ""} ` ||
                "-"}
            </span>
          );
        },
      };
    }

    if (col.key === "orderStatus") {
      return {
        ...col,
        render: (text, record) => {
          const status = record.orderStatus
            ? orderStatus.find((status) => {
                return status.value === record.orderStatus;
              })
            : "-";
          return <span>{status.label}</span>;
        },
      };
    }

    if (col.key === "docStatus") {
      return {
        ...col,
        render: (text) => {
          return (
            <span>
              {text === "O" ? t("open") : text === "C" ? t("closed") : text}
            </span>
          );
        },
      };
    }

    return col;
  });

  return (
    <div className="flex h-full w-full overflow-y-auto">
      <Menubar />
      <div className="h-screen w-full overflow-y-auto">
        <Navbar />
        <h1 className="font-poppins ml-4 mt-10 text-xl font-bold text-black sm:ml-5 sm:mt-14 sm:text-2xl">
          {t("finishedSales")}
        </h1>
        <div className="mt-10 w-full border-[1px] border-[#E8E8E8] sm:mt-14"></div>

        <div className="ml-5 mt-5 flex gap-5">
          <div className="flex flex-col gap-3">
            <p>{t("startDate")}</p>
            <Input
              type="date"
              value={startDate}
              className="w-[150px]"
              onChange={handleStartDate}
              max={endDate}
            />
          </div>
          <div className="flex flex-col gap-3">
            <p>{t("endDate")}</p>
            <Input
              type="date"
              value={endDate}
              className="w-[150px]"
              onChange={handleEndDate}
              min={startDate}
              max={moment().format("YYYY-MM-DD")}
            />
          </div>
        </div>

        <div className="mt-10 overflow-x-auto" ref={tableContainerRef}>
          <Table
            columns={columns}
            dataSource={fdata}
            pagination={false}
            loading={loading || isLoadingMore}
            rowKey={(record) => record.docEntry}
            onRow={(record) => ({
              onClick: () => handleRowClick(record),
            })}
            scroll={{
              y: "calc(100vh - 400px)",
              scrollToFirstRowOnChange: false,
            }}
            sticky={{
              offsetHeader: 0,
              offsetScroll: 0,
              getContainer: () => window,
            }}
            summary={() => (
              <Table.Summary fixed="bottom">
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={3}>
                    <strong>{t("total")}</strong>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1} align="right">
                    <strong>
                      {new Intl.NumberFormat("fr-FR").format(total)} USD
                    </strong>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={2}
                    colSpan={3}
                  ></Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            )}
          />
        </div>
      </div>
      <FinishedSalesModalWrh
        visible={isModalVisible}
        data={selectedRowData}
        onClose={() => setIsModalVisible(false)}
      />
    </div>
  );
};

export default FinishedSales;
