import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FaSignOutAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { session } from "../services/session";
import { logout } from "../slice/mainSlice";
import { useTranslation } from "react-i18next";
import { Select, Tooltip, Menu } from "antd";
import Flag from "react-world-flags";

const { Option } = Select;

const Navbar = () => {
  const { t, i18n } = useTranslation();
  const user = useSelector((state) => state.main.user) || "";
  const [isActive, setIsActive] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLanguageChange = (value) => {
    i18n.changeLanguage(value);
    localStorage.setItem("language", value);
  };

  const menu = (
    <Menu onClick={handleLanguageChange}>
      <Menu.Item key="ru">
        <Flag code="RU" style={{ width: 20, marginRight: 8 }} /> Russian
      </Menu.Item>
      <Menu.Item key="uzb">
        <Flag code="UZ" style={{ width: 20, marginRight: 8 }} /> Uzbek
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);
  const handleClick = () => {
    navigate("/auth");
    session.delete();
    dispatch(logout());
  };

  return (
    <div className="relative flex h-[80px] w-full items-center justify-end gap-10 bg-[#FFFFFF] pr-7 drop-shadow-xl">
      <Select
        className="w-32"
        defaultValue={i18n.language}
        onChange={handleLanguageChange}
        style={{ width: 120 }}
      >
        <Option value="en">
          <span className="flex items-center">
            <Flag code="GB" className="mr-2" style={{ width: 20 }} />
            EN
          </span>
        </Option>
        <Option value="ru">
          <span className="flex items-center">
            <Flag code="RU" className="mr-2" style={{ width: 20 }} />
            RU
          </span>
        </Option>
        <Option value="uzb">
          <span className="flex items-center">
            <Flag code="UZ" className="mr-2" style={{ width: 20 }} />
            UZB
          </span>
        </Option>
      </Select>
      <Tooltip>
        <div
          className={"flex items-center gap-2"}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={() => setIsActive(!isActive)}
        >
          <div className="flex h-8 w-8 items-center justify-center rounded-full bg-[#0085FF1A] text-[#0085FF]">
            {user[0]}
          </div>
          <h1 className="font-inter text-base font-medium">{user}</h1>
        </div>
      </Tooltip>
      {(isHovered || isActive) && (
        <button
          className="absolute bottom-[-50px] right-7 ml-10 flex items-center gap-5 rounded bg-red-500 p-2 text-white"
          onClick={handleClick}
        >
          {t("logout")} {"  "}
          <FaSignOutAlt />
        </button>
      )}
    </div>
  );
};

export default Navbar;
