export const formatMoney = (value) => {
  if (!value) return "";
  const stringValue = typeof value === "string" ? value : String(value);
  const numericValue = stringValue.replace(/[^\d.]/g, "");

  return numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

export const handleInputChangeMoney = (e, form, name) => {
  const value = e.target.value;
  const formattedValue = formatMoney(value.replace(/\s/g, ""));
  form.setFieldsValue({ [name]: formattedValue });
};

export const removeSpaces = (value) => {
  return String(value)
    .replace(/[^0-9.,]/g, "")
    .replace(/\s+/g, "");
};
