import React, { useState, useMemo, useEffect, useCallback } from "react";
import { Input, DatePicker, Table } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { http } from "../../../../services/http";
import debounce from "lodash.debounce";
import Highlighter from "react-highlight-words";
import { useTranslation } from "react-i18next";
import Header from "../../../../components/header";
import moment from "moment";
import { useSelector } from "react-redux";
import CreateOutgoingPayments from "../../../../components/SalesManager/outgoing-payments/create-outgoing-wrh-modal";
import ViewOutgoingPayments from "../../../../components/WhrManager/viewOutgoingPayments";

const { RangePicker } = DatePicker;
const formattedDate = moment().format("YYYY-MM-DD");

const DriversAndEmployees = () => {
  const role = useSelector((state) => state.main.role);
  const { t } = useTranslation();

  const [fdata, setFData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [cardName, setCardName] = useState("");
  const [slpName, setSlpName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState(formattedDate);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isViewModalVisible, setIsViewModalVisible] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState("");
  const [activeTab, setActiveTab] = useState("tab1");

  const fetchData = useCallback(
    async (page, ScardName, SphoneNumber, SstartDate, SendDate) => {
      try {
        setLoading(true);

        let url = `api/sales/outgoing-payments-and-business-partners?CardType=S&CardName=${ScardName}&PhoneNumber=${SphoneNumber}&StartDate=${SstartDate}&EndDate=${SendDate}&Skip=${page}`;

        const { data } = await http.get(url);
        const formattedData = data.data;
        const maxsulotLengths = formattedData.length;

        const hasMore = maxsulotLengths === 20 ? true : false;
        setFData(formattedData);
        setHasMoreData(hasMore);
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    },
    [cardName, phoneNumber, startDate, endDate, slpName],
  );

  useEffect(() => {
    fetchData(currentPage, cardName, phoneNumber, startDate, endDate);
  }, [fetchData, currentPage, cardName, startDate, endDate, phoneNumber]);

  const handleNextPage = () => {
    if (hasMoreData) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  let searchInput = null;

  const handleDateRangeChange = (dates, confirm, dataIndex) => {
    debouncedSearch(dates, dataIndex, confirm);
  };

  const handleStartDate = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDate = (e) => {
    setEndDate(e.target.value);
  };

  const debouncedSearch = useMemo(
    () =>
      debounce((value, dataIndex, confirm) => {
        if (dataIndex === "cardName") {
          if (value) {
            setCardName(value);
          } else {
            setCardName("");
          }
        } else if (dataIndex === "phoneNumber") {
          if (value) {
            setPhoneNumber(value);
          } else {
            setPhoneNumber("");
          }
        } else if (dataIndex === "sana") {
          if (value) {
            const formattedStartDate = new Date(value[0]).toLocaleString();
            const formattedEndDate = new Date(value[1]).toLocaleString();

            if (formattedStartDate && formattedEndDate) {
              setStartDate(moment(formattedStartDate).format("YYYY-MM-DD"));
              setEndDate(moment(formattedEndDate).format("YYYY-MM-DD"));
            }
          } else {
            setStartDate("");
            setEndDate("");
          }
        } else {
        }
        confirm();
      }, 3000),
    [fetchData, currentPage],
  );
  const getColumnSearchProps = (dataIndex) => {
    if (dataIndex === "sana") {
      return {
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
          <div style={{ padding: 8 }}>
            <RangePicker
              value={selectedKeys && selectedKeys[0] ? selectedKeys : []}
              onChange={(dates) => {
                setSelectedKeys(dates ? dates : []);
                handleDateRangeChange(dates, confirm, dataIndex);
              }}
              style={{ marginBottom: 8, display: "block" }}
              disabledDate={(current) =>
                current && current > moment().endOf("day")
              }
            />
          </div>
        ),
        filterIcon: (filtered) => (
          <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
        ),
        onFilter: (value, record) => {
          if (Array.isArray(value) && value.length === 2) {
            const [startDate, endDate] = value;
            const recordDate = moment(record[dataIndex]);
            return recordDate.isBetween(startDate, endDate, "[]");
          }
          return true;
        },
        render: (text) => text,
      };
    }
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`${t("search")} ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              handleSearch(e, confirm, dataIndex);
            }}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          ? record[dataIndex]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : "",
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.select(), 100);
        }
      },
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        ) : (
          text
        ),
    };
  };
  const handleSearch = (e, confirm, dataIndex) => {
    const value = e.target.value;
    debouncedSearch(value, dataIndex, confirm);
  };

  // const columns = columnAcceptPaymentCustomer(t, getColumnSearchProps);
  const columns = [
    {
      title: t("date"),
      dataIndex: "docDate",
      key: "sana",
      render: (value) => moment.utc(value).format("DD.MM.YYYY"),
    },
    {
      title: t("mijoz"),
      dataIndex: "cardName",
      key: "mijoz",
      ...getColumnSearchProps("cardName"),
    },
    {
      title: t("phoneNum"),
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render: (value) => value || "-",

      ...getColumnSearchProps("phoneNumber"),
    },

    {
      title: t("docNum"),
      dataIndex: "docNum",
      key: "docNum",
    },
    {
      title: t("priceSum"),
      dataIndex: "cashSum",
      key: "cashSum",
      render: (value, record) =>
        `${
          record.docCurrency === "UZS"
            ? new Intl.NumberFormat("fr-FR").format(record.cashSumFC)
            : new Intl.NumberFormat("fr-FR").format(value)
        }`,
    },
    {
      title: t("currency"),
      dataIndex: "docCurrency",
      key: "docCurrency",
    },
  ];

  const refetchData = () => {
    setCurrentPage(0);
    fetchData(currentPage, cardName, phoneNumber, startDate, endDate);
  };

  const handleRowClick = (record) => {
    setSelectedRowData(record);
    setIsViewModalVisible(true);
    console.log("VewModal Clicked", record);
  };
  return (
    <div>
      <h1 className="font-poppins ml-4 mt-10 text-xl font-bold text-black sm:ml-5 sm:mt-14 sm:text-2xl">
        {t("driversAndEmployees")}
      </h1>
      <div className="mt-10 w-full border-[1px] border-[#E8E8E8] sm:mt-14"></div>

      <div className="ml-5 mt-5 flex gap-5">
        <div className="flex flex-col gap-3">
          <p>{t("startDate")}</p>
          <Input
            type="date"
            value={startDate}
            className="w-[150px]"
            onChange={handleStartDate}
            max={endDate}
          />
        </div>
        <div className="flex flex-col gap-3">
          <p>{t("endDate")}</p>
          <Input
            type="date"
            value={endDate}
            className="w-[150px]"
            onChange={handleEndDate}
            min={startDate}
            max={formattedDate}
          />
        </div>
      </div>
      <div className="mt-6 flex w-full items-center justify-end gap-4 px-4 sm:mt-10 sm:gap-7 sm:px-10">
        <button
          onClick={() => {
            setIsModalVisible(true);
          }}
          className="h-[40px] w-[150px] rounded-2xl bg-[#0A4D68] text-white sm:w-[200px]"
        >
          {t("Add")}
        </button>
      </div>
      <div className="ml-4 mt-6 sm:ml-5 sm:mt-10">
        {handleNextPage && (
          <div className="mb-4 flex flex-col justify-between sm:flex-row">
            <div className="font-nunitto font-bold">
              {t("page")} : {currentPage + 1}
            </div>
            <div className="mt-2 flex gap-2 sm:mr-10">
              <button
                onClick={handlePreviousPage}
                disabled={currentPage === 0}
                className="h-[40px] w-[100px] rounded-2xl bg-gray-300 text-gray-700 disabled:bg-gray-200 disabled:text-gray-400 sm:w-[100px]"
              >
                {t("previous")}
              </button>
              <button
                onClick={handleNextPage}
                disabled={!hasMoreData}
                className="h-[40px] w-[100px] rounded-2xl bg-[#0A4D68] text-white disabled:bg-gray-200 disabled:text-gray-400 sm:w-[100px]"
              >
                {t("next")}
              </button>
            </div>
          </div>
        )}
        <div className="m-2 overflow-x-auto">
          <Table
            columns={columns}
            dataSource={fdata}
            pagination={false}
            className="mt-6 w-full sm:mt-10"
            rowKey={(record) => record.docEntry}
            sticky={true}
            scroll={{ x: 900 }}
            loading={loading}
            onRow={(record) => ({
              onClick: () => {
                handleRowClick(record);
              },
            })}
          />
        </div>
      </div>

      <CreateOutgoingPayments
        isModalVisible={isModalVisible}
        refetchTable={() => {
          fetchData(0, cardName, phoneNumber, "", endDate);
        }}
        onClose={() => {
          setIsModalVisible(false);
        }}
      />
      <ViewOutgoingPayments
        visible={isViewModalVisible}
        refetchTable={() => {
          fetchData(0, cardName, phoneNumber, startDate, endDate);
        }}
        onClose={() => {
          setIsViewModalVisible(false);
        }}
        data={selectedRowData}
      />
    </div>
  );
};

export default DriversAndEmployees;
