import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import LayoutAccountant from "../../../components/LayoutAccountant";
import { Button, Table, Input, Select, Tag, message } from "antd";
import { useTranslation } from "react-i18next";
import ChangeRateModal from "../../../components/Accountant/ChangeRate";
import { getToday } from "../../../utils/getDays";
import { debouncedSet } from "../../../utils/debounce";
import { useLocation } from "react-router-dom";
import { http } from "../../../services/http";
import debounce from "lodash.debounce";
import { numberWithSpacesIntl } from "../../../utils/numberWithSpaces";
import moment from "moment";

const ActSverkaAccountant = ({ name }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const code = location.pathname.split("/")[2];
  const tableContainerRef = useRef(null);
  const isLoadingRef = useRef(false);
  const menuName = localStorage.getItem("menuName") ?? "";

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState(getToday());
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const pageSize = "20";
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  const columns = [
    {
      title: t("transId"),
      dataIndex: "transId",
      key: "transId",
    },

    {
      title: t("date"),
      dataIndex: "dueDate",
      key: "dueDate",
      render: (value) => (value ? moment(value).format("DD.MM.YYYY") : "-"),
    },

    {
      title: t("Kirim puli"),
      dataIndex: "fcDebit",
      key: "fcDebit",
      render: (text) => {
        return <span>{numberWithSpacesIntl(text)}</span>;
      },
    },
    {
      title: t("Chiqim puli"),
      dataIndex: "fcCredit",
      key: "fcCredit",
      render: (text) => {
        return <span>{numberWithSpacesIntl(text)}</span>;
      },
    },
    {
      title: t("Schot kodi / BP kodi"),
      dataIndex: "contraAct",
      key: "contraAct",
    },
    {
      title: t("Schot nomi / BP nomi"),
      dataIndex: "contraName",
      key: "contraName",
    },
    {
      title: t("cumulativeBalanceLC"),
      dataIndex: "cumulativeBalanceLC",
      key: "cumulativeBalanceLC",
      render: (text) => {
        return <span>{numberWithSpacesIntl(text)}</span>;
      },
    },
    {
      title: t("cumulativeBalanceFC"),
      dataIndex: "cumulativeBalanceFC",
      key: "cumulativeBalanceFC",
      render: (text) => {
        return <span>{numberWithSpacesIntl(text)}</span>;
      },
    },
  ];

  const fetchData = useCallback(
    async ({
      page,
      pageSize,
      code,
      startDate,
      endDate,
      isLoadMore = false,
    }) => {
      setLoading(true);
      if (isLoadingRef.current) return;

      try {
        isLoadingRef.current = true;
        if (!isLoadMore) {
          setLoading(true);
        } else {
          setIsLoadingMore(true);
        }
        const { data } = await http.get(
          `api/payments/payments-akt-sverka?startDate=${startDate}&endDate=${endDate}&acctCode=${code}&skip=${page}&pageSize=${pageSize}`,
        );
        if (page === 0) {
          setData(data);
        } else {
          setData((prevData) => [...prevData, ...data]);
        }
        setHasMore(data.length === Number(pageSize));
      } catch (err) {
        message.error(err?.response?.data?.message);
        setHasMore(false);
      } finally {
        setLoading(false);
        setIsLoadingMore(false);
        isLoadingRef.current = false;
      }
    },
    [],
  );

  useEffect(() => {
    fetchData({
      page: currentPage,
      pageSize,
      code,
      startDate,
      endDate,
      isLoadMore: currentPage > 0,
    });
  }, [currentPage, startDate, endDate, code]);

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const handleSearch = (value) => {
    console.log(value);
  };

  const switchCurrency = (currency) => {
    switch (currency) {
      case "UZS":
        return "so'm";
      case "USD":
        return "$";
      case "EUR":
        return "€";
      case "RUB":
        return "₽";
      default:
        return "so'm";
    }
  };

  const debouncedSetPage = useMemo(
    () =>
      debounce(() => {
        if (!isLoadingRef.current) {
          setCurrentPage((prev) => prev + 1);
        }
      }, 300),
    [],
  );

  const handleScroll = useCallback(
    (e) => {
      if (!hasMore || loading || isLoadingMore || isLoadingRef.current) return;

      const { scrollTop, scrollHeight, clientHeight } = e.target;

      if (scrollHeight - scrollTop <= clientHeight + 50) {
        debouncedSetPage();
      }
    },
    [hasMore, loading, isLoadingMore, debouncedSetPage],
  );

  useEffect(() => {
    const tableBody =
      tableContainerRef.current?.getElementsByClassName("ant-table-body")[0];
    if (tableBody) {
      tableBody.addEventListener("scroll", handleScroll);
      return () => tableBody.removeEventListener("scroll", handleScroll);
    }
  }, [handleScroll]);

  return (
    <LayoutAccountant>
      <div className="p-5 px-10">
        <h1 className="text-2xl font-bold text-basic">
          {t("act-sverka")}-{code}-{menuName}
        </h1>
        <div className="mt-10 rounded-lg border-t-4 border-t-basic bg-white p-5 drop-shadow-md">
          <span className="text-xl font-bold text-basic">
            {t("filtersPayments")}
          </span>
          <div className="mt-5 grid grid-cols-3 items-end gap-5">
            <div className="flex flex-col items-start gap-2">
              <span className="font-semibold text-basic">{t("startDate")}</span>
              <Input
                type="date"
                className="w-full"
                value={startDate}
                max={endDate}
                onChange={(e) => {
                  setData([]);
                  setStartDate(e.target.value);
                  setCurrentPage(0);
                }}
              />
            </div>
            <div className="flex flex-col items-start gap-2">
              <span className="font-semibold text-basic">{t("endDate")}</span>
              <Input
                type="date"
                className="w-full"
                value={endDate}
                min={startDate}
                onChange={(e) => {
                  setData([]);
                  setEndDate(e.target.value);
                  setCurrentPage(0);
                }}
              />
            </div>
          </div>
        </div>
        <div className="mt-10 rounded-lg border-t-4 border-t-basic bg-white drop-shadow-md">
          <div className="p-5">
            <div className="flex items-center justify-between">
              <span className="text-xl font-bold text-basic">
                {t("act-sverka")}
              </span>
            </div>
            <div className="mt-5 overflow-x-auto" ref={tableContainerRef}>
              <Table
                columns={columns}
                dataSource={data}
                className="mt-10"
                pagination={false}
                loading={loading || isLoadingMore}
                rowKey={(record) => record.transId}
                scroll={{
                  y: "calc(100vh - 300px)",
                  // x: "1500px",
                  scrollToFirstRowOnChange: false,
                }}
                // summary={(pageData) => {
                //   const totalAmount = pageData.reduce((acc, item) => {
                //     return acc + item.fcDebit;
                //   }, 0);
                //   return (
                //     <Table.Summary fixed="bottom">
                //       <Table.Summary.Row>
                //         <Table.Summary.Cell index={0} colSpan={5}>
                //           <span className="font-bold text-basic">
                //             {t("total")}
                //           </span>
                //         </Table.Summary.Cell>
                //         <Table.Summary.Cell index={1}>
                //           <span className="font-bold text-basic">
                //             {new Intl.NumberFormat("fr-FR").format(totalAmount)}{" "}
                //             $
                //           </span>
                //         </Table.Summary.Cell>
                //         <Table.Summary.Cell index={2}></Table.Summary.Cell>
                //       </Table.Summary.Row>
                //     </Table.Summary>
                //   );
                // }}
              />
            </div>
          </div>
        </div>
      </div>
      <ChangeRateModal
        visible={isModalVisible}
        data={data}
        onClose={handleModalClose}
      />
    </LayoutAccountant>
  );
};

export default ActSverkaAccountant;
