import { Modal, Select, Input, Button, message } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CloseOutlined } from "@ant-design/icons";
import { http } from "../../../services/http";

const ChangeRateModal = ({ visible, data, onClose, refetch }) => {
  const { t } = useTranslation();

  const [usdCash, setUsdCash] = useState("");
  const [rubCash, setRubCash] = useState("");
  const [bank, setBank] = useState("");
  const [banksData, setBanksData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");

  const getBanksData = async () => {
    try {
      const { data } = await http.get(`api/dashboard/banks`);
      setBanksData(data);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getBanksData();
  }, []);

  const handleBtnClick = async () => {
    setLoading(true);
    const postData = {
      code: String(code),
      u_Bank: bank,
      u_USDtoUZS: +usdCash,
      u_RUBLtoUZS: +rubCash,
    };
    try {
      const { data } = await http.patch(
        `api/dashboard/exchange-rates`,
        postData,
      );
      onClose();
      resetForm();
      refetch();
    } catch (err) {
      message.error(err?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setUsdCash("");
    setRubCash("");
    setBank("");
    setCode("");
  };

  if (!data) return null;

  return (
    <Modal
      title={
        <h1 className="mt-[-10px] font-sans text-xl font-extrabold text-[#000000]">
          {t("changeRate")}
        </h1>
      }
      open={visible}
      onCancel={onClose}
      footer={null}
      closeIcon={<CloseOutlined />}
      width="30%"
      focusTriggerAfterClose={true}
      centered={true}
    >
      <div className="mt-7 w-full px-4 sm:px-10">
        <div className="flex flex-col gap-5">
          <div className="flex items-center gap-3">
            <p className="w-24 text-left font-semibold text-gray-700">
              {t("bank")}
            </p>
            <Select
              value={bank}
              className="flex h-10 w-[200px] items-center justify-center"
              onChange={(val) => {
                setBank(val);
                data?.map((item) => {
                  if (item.u_Bank === val) {
                    setCode(item.code);
                    setRubCash(item.u_RUBLtoUZS);
                    setUsdCash(item.u_USDtoUZS);
                    // message.info(item.code);
                  }
                });
              }}
              options={banksData
                .filter((item) => !item?.descr?.includes("SAP"))
                .map((item) => {
                  return {
                    value: item.fldValue,
                    label: item.descr,
                  };
                })}
            />
          </div>
          <div className="flex items-center gap-3">
            <p className="w-24 text-left font-semibold text-gray-700">
              {t("usdRate")}
            </p>

            <Input
              value={usdCash}
              className="h-10 w-[200px] border-[1px] border-[#D0D0D0]"
              onChange={(e) => setUsdCash(e.target.value)}
            />
          </div>

          <div className="flex items-center gap-3">
            <p className="w-24 font-semibold text-gray-700">{t("rubRate")}</p>
            <Input
              value={rubCash}
              className="h-10 w-[200px] border-[1px] border-[#D0D0D0]"
              onChange={(e) => setRubCash(e.target.value)}
            />
          </div>
        </div>
        <Button
          type="primary"
          loading={loading}
          onClick={handleBtnClick}
          className="mt-5 w-full bg-basic text-white hover:!bg-blue-950"
        >
          {t("changeRate")}
        </Button>
      </div>
    </Modal>
  );
};

export default ChangeRateModal;
