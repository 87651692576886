import React, { useState, useMemo, useEffect, useCallback } from "react";
import { Input, DatePicker } from "antd";
import { SearchOutlined } from "@ant-design/icons";
// import Menubar from "../../../../components/SalesManager/App";
import { Menubar as MenuSales } from "../../../../components/SalesManager/App";
import { Menubar as MenuWhs } from "../../../../components/WhrManager/App";
import { http } from "../../../../services/http";
import CreateSaleModal from "../../../../components/SalesManager/createSaleModal";
import RowDataSales from "../../../../components/SalesManager/row-data-shipment-Sales";
import debounce from "lodash.debounce";
import Highlighter from "react-highlight-words";
import { useTranslation } from "react-i18next";
import Header from "../../../../components/header";
import moment from "moment";
import { get } from "lodash";
import CreateIncomingPayments from "../../../../components/SalesManager/incoming-payments/create-incoming-wrh-modal";
import { useSelector } from "react-redux";
import ViewIncomingPayments from "../../../../components/WhrManager/viewIncomingPayments";
const { RangePicker } = DatePicker;

const today = new Date();
const day = ("0" + today.getDate()).slice(-2);
const month = ("0" + (today.getMonth() + 1)).slice(-2);
const year = today.getFullYear();
const formattedDate = `${year}-${month}-${day}`;

const IncomingPayments = () => {
  const role = useSelector((state) => state.main.role);

  const [fdata, setFData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [cardName, setCardName] = useState("");
  const [slpName, setSlpName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState(formattedDate);
  const [isViewModalVisible, setIsViewModalVisible] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState("");

  const { t } = useTranslation();

  const fetchData = useCallback(
    async (page, ScardName, SphoneNumber, SstartDate, SendDate) => {
      try {
        setLoading(true);

        let url = `api/sales/incoming-payments-and-business-partners?CardType=C&CardName=${ScardName}&PhoneNumber=${SphoneNumber}&StartDate=${SstartDate}&EndDate=${SendDate}&Skip=${page}`;

        const { data } = await http.get(url);
        const formattedData = data.data;
        const maxsulotLengths = formattedData.length;

        const hasMore = maxsulotLengths === 20 ? true : false;
        setFData(formattedData);
        setHasMoreData(hasMore);
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    },
    [cardName, phoneNumber, startDate, endDate, slpName],
  );

  useEffect(() => {
    fetchData(currentPage, cardName, phoneNumber, startDate, endDate);
  }, [fetchData, currentPage, cardName, startDate, endDate, phoneNumber]);

  const handleNextPage = () => {
    if (hasMoreData) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  let searchInput = null;

  const handleDateRangeChange = (dates, confirm, dataIndex) => {
    debouncedSearch(dates, dataIndex, confirm);
  };

  const handleStartDate = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDate = (e) => {
    setEndDate(e.target.value);
  };

  const debouncedSearch = useMemo(
    () =>
      debounce((value, dataIndex, confirm) => {
        if (dataIndex === "cardName") {
          if (value) {
            setCardName(value);
          } else {
            setCardName("");
          }
        } else if (dataIndex === "phoneNumber") {
          if (value) {
            setPhoneNumber(value);
          } else {
            setPhoneNumber("");
          }
        } else if (dataIndex === "sana") {
          if (value) {
            const formattedStartDate = new Date(value[0]).toLocaleString();
            const formattedEndDate = new Date(value[1]).toLocaleString();

            if (formattedStartDate && formattedEndDate) {
              setStartDate(moment(formattedStartDate).format("YYYY-MM-DD"));
              setEndDate(moment(formattedEndDate).format("YYYY-MM-DD"));
            }
          } else {
            setStartDate("");
            setEndDate("");
          }
        } else {
        }
        confirm();
      }, 3000),
    [fetchData, currentPage],
  );
  const getColumnSearchProps = (dataIndex) => {
    if (dataIndex === "sana") {
      return {
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
          <div style={{ padding: 8 }}>
            <RangePicker
              value={selectedKeys && selectedKeys[0] ? selectedKeys : []}
              onChange={(dates) => {
                setSelectedKeys(dates ? dates : []);
                handleDateRangeChange(dates, confirm, dataIndex);
              }}
              style={{ marginBottom: 8, display: "block" }}
              disabledDate={(current) =>
                current && current > moment().endOf("day")
              }
            />
          </div>
        ),
        filterIcon: (filtered) => (
          <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
        ),
        onFilter: (value, record) => {
          if (Array.isArray(value) && value.length === 2) {
            const [startDate, endDate] = value;
            const recordDate = moment(record[dataIndex]);
            return recordDate.isBetween(startDate, endDate, "[]");
          }
          return true;
        },
        render: (text) => text,
      };
    }
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`${t("search")} ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              handleSearch(e, confirm, dataIndex);
            }}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          ? record[dataIndex]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : "",
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.select(), 100);
        }
      },
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        ) : (
          text
        ),
    };
  };
  const handleSearch = (e, confirm, dataIndex) => {
    const value = e.target.value;
    debouncedSearch(value, dataIndex, confirm);
  };
  const columns = [
    {
      title: t("date"),
      dataIndex: "docDate",
      key: "sana",
      render: (value) => moment.utc(value).format("DD.MM.YYYY"),
    },
    {
      title: t("mijoz"),
      dataIndex: "cardName",
      key: "mijoz",
      ...getColumnSearchProps("cardName"),
    },
    {
      title: t("phoneNum"),
      dataIndex: "phoneNumber",
      key: "mijoz",
      ...getColumnSearchProps("phoneNumber"),
    },

    {
      title: t("priceSum"),
      dataIndex: "cashSum",
      key: "cashSum",
      render: (value, record) =>
        `${record.docCurrency === "UZS" ? new Intl.NumberFormat("fr-FR").format(record.cashSumFC) : new Intl.NumberFormat("fr-FR").format(value)}`,
    },
    {
      title: t("currency"),
      dataIndex: "docCurrency",
      key: "docCurrency",
    },
    {
      title: t("docNum"),
      dataIndex: "docNum",
      key: "docNum",
    },
    {
      title: t("Dollar kursi"),
      dataIndex: "docRate",
      key: "docRate",
    },
  ];
  const handleRowClick = (record) => {
    setSelectedRowData(record);
    setIsViewModalVisible(true);
    console.log("VewModal Clicked", record);
  };

  return (
    <div className="flex h-full w-full overflow-y-auto">
      {role === "Whsmanager" ? <MenuWhs /> : <MenuSales />}
      <div className="h-screen w-full overflow-y-auto">
        <Header
          title={"incoming-payments"}
          currentPage={currentPage + 1}
          handlePreviousPage={handlePreviousPage}
          handleNextPage={handleNextPage}
          hasMoreData={hasMoreData}
          columns={columns}
          fdata={fdata}
          // onBtnClick={() => {
          //   setIsModalVisible(true);
          // }}
          loading={loading}
          handleRowClick={handleRowClick}
          onStartDateChange={handleStartDate}
          onEndDateChange={handleEndDate}
          startDate={startDate}
          endDate={endDate}
          formattedDate={formattedDate}
        />
      </div>
      <CreateIncomingPayments
        isModalVisible={isModalVisible}
        refetchTable={() => {
          fetchData(0, cardName, phoneNumber, "", endDate);
        }}
        onClose={() => {
          setIsModalVisible(false);
        }}
      />
      <ViewIncomingPayments
        visible={isViewModalVisible}
        refetchTable={() => {
          fetchData(0, cardName, phoneNumber, startDate, endDate);
        }}
        onClose={() => {
          setIsViewModalVisible(false);
        }}
        data={selectedRowData}
      />
    </div>
  );
};

export default IncomingPayments;
