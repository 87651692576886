import React, { useRef, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  login,
  setToken,
  setEmployeeId,
  setRole,
  setSalesPersonCode,
  setSelectedPath,
} from "../slice/mainSlice";
import { Form, Input, Typography, Button, message, Spin } from "antd";
import { session } from "../services/session";
import { http } from "../services/http";
import { useTranslation } from "react-i18next";
import OneSignal from "react-onesignal";
import { Lock, User } from "lucide-react";
import { motion } from "framer-motion";
import Stars from "../components/LoginStars";

// import "../assets/style/main.css";
const Auth = () => {
  const { t } = useTranslation();
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [isMeLoading, setMeLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await http.get("api/auth/me");
        if (data) {
          const {
            accessToken: token,
            firstName,
            lastName,
            jobTitle: role,
            employeeId,
            salesPersonCode,
          } = data;
          const user = `${firstName} ${lastName}`;
          dispatch(login(user));
          dispatch(setToken(token));
          dispatch(setRole(role));
          dispatch(setEmployeeId(employeeId));
          dispatch(setSalesPersonCode(salesPersonCode));
          const prevLocation = sessionStorage.getItem("prevLocation");
          if (role === "Cashier") {
            navigate(prevLocation || "/accepting-payment-from-customer");
          } else if (role === "Accountant") {
            navigate(prevLocation || "/dashboard-accountant");
          } else navigate(prevLocation || "/my-sales");
          const extractedData = prevLocation.slice(1);
          dispatch(setSelectedPath(extractedData || "my-sales"));
        }
      } catch (err) {
        console.log(err);
      } finally {
        setMeLoading(false);
      }
    };

    fetchData();
  }, [dispatch, navigate]);

  const handleSubmit = async (values) => {
    const { email, password } = values;
    console.log("deviceID", OneSignal.User.PushSubscription.id);
    const deviceId = OneSignal.User.PushSubscription.id || "";
    await OneSignal.login(email).then(() => {
      console.log("Logged in successfully.");
    });

    setLoading(true);
    try {
      const { data } = await http.get(
        `api/auth?${deviceId ? `DeviceId=${deviceId}&` : ""}EmployeeCode=${email}&ExternalEmployeeNumber=${password}`,
      );
      const {
        accessToken: token,
        firstName,
        lastName,
        jobTitle: role,
        employeeId,
        salesPersonCode,
      } = data;
      const user = `${firstName} ${lastName}`;

      if (
        role === "Whsmanager" ||
        role === "Seller" ||
        role === "Cashier" ||
        role === "Accountant"
      ) {
        session.set(token);
        dispatch(login(user));
        dispatch(setToken(token));
        dispatch(setRole(role));
        dispatch(setEmployeeId(employeeId));
        dispatch(setSalesPersonCode(salesPersonCode));

        message.success(t("successMessage", { user }));
        if (role === "Cashier") {
          navigate("/accepting-payment-from-customer");
        } else if (role === "Accountant") {
          navigate("/dashboard-accountant");
        } else navigate("/my-sales");
        dispatch(setSelectedPath("my-sales"));
      } else {
        message.error(t("accessDenied"));
      }
    } catch (err) {
      message.error(t("userNotFound"));
    } finally {
      setLoading(false);
    }
  };

  if (isMeLoading) {
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <Spin size="large" />
      </div>
    );
  }

  return (
    // <div className="relative flex h-screen w-full animate-gradient flex-col items-center justify-center bg-gradient-to-l from-basic to-[#1c1b1b] bg-[length:200%_200%]">
    //   <div className="flex w-[400px] items-center justify-center rounded-xl bg-white/90 opacity-90 shadow-2xl">
    //     <Form
    //       onFinish={handleSubmit}
    //       className="flex w-full flex-col items-center p-8"
    //     >
    //       <div className="mb-6 flex flex-col items-center gap-2">
    //         <Typography.Title
    //           level={2}
    //           className="font-montserrat text-3xl font-semibold !text-basic"
    //         >
    //           {t("login")}
    //         </Typography.Title>
    //       </div>

    //       <Form.Item
    //         name="email"
    //         rules={[
    //           {
    //             required: true,
    //             message: t("userNameRequired"),
    //           },
    //         ]}
    //         className="w-full"
    //       >
    //         <div className="flex flex-col gap-2">
    //           <label
    //             htmlFor="email"
    //             className="font-montserrat text-base font-medium text-basic"
    //           >
    //             {t("userName")}
    //           </label>
    //           <Input
    //             id="email"
    //             ref={emailRef}
    //             prefix={<User color="gray" size={20} />}
    //             placeholder={t("enterUserName")}
    //             autoFocus
    //             className="h-10 w-full"
    //           />
    //         </div>
    //       </Form.Item>

    //       <Form.Item
    //         name="password"
    //         rules={[
    //           {
    //             required: true,
    //             message: t("passwordRequired"),
    //           },
    //         ]}
    //         className="w-full"
    //       >
    //         <div className="mt-2 flex flex-col gap-2">
    //           <label
    //             htmlFor="password"
    //             className="font-montserrat text-base font-medium text-basic"
    //           >
    //             {t("Password")}
    //           </label>
    //           <Input.Password
    //             id="password"
    //             ref={passwordRef}
    //             placeholder={t("enterPassword")}
    //             prefix={<Lock color="gray" size={20} className="mr-1" />}
    //             className="h-10 w-full"
    //           />
    //         </div>
    //       </Form.Item>

    //       <div className="mt-6 w-full">
    //         <Button
    //           type="default"
    //           htmlType="submit"
    //           loading={loading}
    //           className="font-montserrat h-10 w-full bg-gradient-to-br from-[#23acc1] to-[#0A4D68] text-base font-bold text-white hover:!border-[#23acc1] hover:!bg-gradient-to-r hover:!from-[#0A4D68] hover:!to-[#23acc1] hover:!text-white"
    //         >
    //           {t("SignIn")}
    //         </Button>
    //       </div>
    //     </Form>
    //   </div>
    //   <div className="absolute bottom-10 py-2 text-center text-sm">
    //     <footer className="animate-gradient bg-inherit bg-gradient-to-l from-[#23acc1] to-[#0A4D68] bg-clip-text text-transparent">
    //       Business Intelligence Solutions® 2025
    //     </footer>
    //   </div>
    // </div>

    <div className="relative flex h-screen w-full flex-col items-center justify-center overflow-hidden bg-gradient-to-l from-basic to-[#1c1b1b] bg-[length:200%_200%]">
      <Stars />

      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
        className="flex w-[400px] items-center justify-center rounded-xl bg-white/90 shadow-2xl backdrop-blur-sm"
      >
        <Form
          onFinish={handleSubmit}
          className="flex w-full flex-col items-center p-8"
        >
          <div className="mb-6 flex flex-col items-center gap-2">
            <Typography.Title
              level={2}
              className="font-montserrat text-3xl font-semibold !text-basic"
            >
              {t("login")}
            </Typography.Title>
          </div>

          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: t("userNameRequired"),
              },
            ]}
            className="w-full"
          >
            <div className="flex flex-col gap-2">
              <label
                htmlFor="email"
                className="font-montserrat text-base font-medium text-basic"
              >
                {t("userName")}
              </label>
              <Input
                id="email"
                ref={emailRef}
                prefix={<User color="gray" size={20} />}
                placeholder={t("enterUserName")}
                autoFocus
                className="h-10 w-full"
              />
            </div>
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: t("passwordRequired"),
              },
            ]}
            className="w-full"
          >
            <div className="mt-2 flex flex-col gap-2">
              <label
                htmlFor="password"
                className="font-montserrat text-base font-medium text-basic"
              >
                {t("Password")}
              </label>
              <Input.Password
                id="password"
                ref={passwordRef}
                placeholder={t("enterPassword")}
                prefix={<Lock color="gray" size={20} className="mr-1" />}
                className="h-10 w-full"
              />
            </div>
          </Form.Item>
          <div className="mt-6 w-full">
            <Button
              type="default"
              htmlType="submit"
              loading={loading}
              className="font-montserrat h-10 w-full bg-gradient-to-br from-[#23acc1] to-[#0A4D68] text-base font-bold text-white hover:!border-[#23acc1] hover:!bg-gradient-to-r hover:!from-[#0A4D68] hover:!to-[#23acc1] hover:!text-white"
            >
              {t("SignIn")}
            </Button>
          </div>
        </Form>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
        className="absolute bottom-10 py-2 text-center text-sm"
      >
        <footer className="animate-gradient bg-inherit bg-gradient-to-l from-[#23acc1] to-[#2885aa] bg-clip-text text-transparent">
          Business Intelligence Solutions® 2025
        </footer>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, ease: "easeOut", delay: 0.3 }}
      ></motion.div>
    </div>
  );
};

export default Auth;
