import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import LayoutAccountant from "../../../components/LayoutAccountant";
import {
  Tooltip,
  Table,
  Input,
  Select,
  AutoComplete,
  Tag,
  message,
} from "antd";
import { useTranslation } from "react-i18next";
import ChangeRateModal from "../../../components/Accountant/ChangeRate";
import { getToday } from "../../../utils/getDays";
import { debouncedSet } from "../../../utils/debounce";
import { useLocation } from "react-router-dom";
import { http } from "../../../services/http";
import { numberWithSpacesIntl } from "../../../utils/numberWithSpaces";
import moment from "moment";
import debounce from "lodash.debounce";

const LoadedPayments = ({ name }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const code = location.pathname.split("/")[2];
  const tableContainerRef = useRef(null);
  const isLoadingRef = useRef(false);
  const menuName = localStorage.getItem("menuName") ?? "";

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState(getToday());
  const [type, setType] = useState("");
  const [bpNameSelected, setBpNameSelected] = useState("");
  const [bpData, setBpData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [bpNameSearch, setBpNameSearch] = useState("");
  const [bpName, setBpName] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [docType, setDocType] = useState("");
  const pageSize = "20";
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [total, setTotal] = useState(0);
  const [codeGet] = useState(code);

  const fetchData = async ({
    code,
    page,
    pageSize,
    bpName,
    startDate,
    endDate,
    type,
    docType,
    isLoadMore = false,
  }) => {
    setLoading(true);
    if (isLoadingRef.current) return;

    try {
      isLoadingRef.current = true;
      if (!isLoadMore) {
        setLoading(true);
      } else {
        setIsLoadingMore(true);
      }
      const { data } = await http.get(
        `api/payments/payments?startDate=${startDate}&endDate=${endDate}&paymentType=${type}&docType=${docType}&bpName=${bpName}&skip=${page}&pageSize=${pageSize}&acctCode=${code}`,
      );
      if (page === 0) {
        setData(data);
      } else {
        setData((prevData) => [...prevData, ...data]);
      }
      setHasMore(data.length === Number(pageSize));
    } catch (err) {
      message.error(err?.response?.data?.message);
      setHasMore(false);
    } finally {
      setLoading(false);
      setIsLoadingMore(false);
      isLoadingRef.current = false;
    }
  };

  const fetchTotal = async ({
    code,
    bpName,
    startDate,
    endDate,
    type,
    docType,
    isLoadMore = false,
  }) => {
    try {
      const { data } = await http.get(
        `api/payments/payments-total?startDate=${startDate}&endDate=${endDate}&paymentType=${type}&docType=${docType}&bpName=${bpName}&acctCode=${code}`,
      );
      setTotal(data[0]);
    } catch (err) {
      message.error(err?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchBpName = async (value) => {
    try {
      const { data } = await http.get(
        `api/bankstatements/business-partners?cardName=${value}`,
      );
      setBpData(data);
    } catch (err) {
      message.error(err?.response?.data?.message);
    }
  };

  useEffect(() => {
    fetchData({
      code: code,
      page: currentPage,
      pageSize,
      bpName,
      startDate,
      endDate,
      type,
      docType,
      isLoadMore: currentPage > 0,
    });
  }, [currentPage, bpName, startDate, endDate, type, docType, code]);

  useEffect(() => {
    fetchTotal({
      code: code,
      bpName,
      startDate,
      endDate,
      type,
      docType,
      isLoadMore: currentPage > 0,
    });
  }, [bpName, startDate, endDate, type, docType, code]);

  useEffect(() => {
    fetchBpName(bpNameSearch);
  }, [bpNameSearch]);

  const columns = [
    {
      title: t("docNum"),
      dataIndex: "docNum",
      key: "docNum",
    },
    {
      title: t("docDate"),
      dataIndex: "docDate",
      key: "docDate",
      render: (value) => moment(value).format("DD.MM.YYYY"),
    },
    {
      title: t("paymentType"),
      dataIndex: "paymentType",
      key: "paymentType",
      render: (text) => {
        return (
          <span>
            {text === "Incoming" ? (
              <Tag className="rounded-xl" color="green">
                {t("Incoming")}
              </Tag>
            ) : (
              <Tag className="rounded-xl" color="red">
                {t("Outgoing")}
              </Tag>
            )}
          </span>
        );
      },
    },

    {
      title: t("bpName"),
      dataIndex: "cardName",
      key: "cardName",
    },
    {
      title: t("paymentAmount"),
      dataIndex: "cashSum",
      key: "cashSum",
      render: (text, record) => {
        return (
          <span>{`${numberWithSpacesIntl(text)} ${switchCurrency(record.docCurr)}`}</span>
        );
      },
    },
    {
      title: t("company"),
      dataIndex: "u_Firma",
      key: "u_Firma",
      render: (text, record) => {
        return (
          text && (
            <Tooltip title={text}>
              <div className="cursor-pointer">{`${text && text.length > 30 ? `${text.slice(0, 30)}...` : text}`}</div>
            </Tooltip>
          )
        );
      },
    },
  ];

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const handleSearchBpName = (value) => {
    debouncedSet(value, setBpNameSearch);
  };

  const switchCurrency = (currency) => {
    switch (currency) {
      case "UZS":
        return "so'm";
      case "USD":
        return "$";
      case "EUR":
        return "€";
      case "RUB":
        return "₽";
      default:
        return "so'm";
    }
  };

  const debouncedSetPage = useMemo(
    () =>
      debounce(() => {
        if (!isLoadingRef.current) {
          setCurrentPage((prev) => prev + 1);
        }
      }, 300),
    [],
  );

  const handleScroll = useCallback(
    (e) => {
      if (!hasMore || loading || isLoadingMore || isLoadingRef.current) return;

      const { scrollTop, scrollHeight, clientHeight } = e.target;

      if (scrollHeight - scrollTop <= clientHeight + 50) {
        debouncedSetPage();
      }
    },
    [hasMore, loading, isLoadingMore, debouncedSetPage],
  );

  useEffect(() => {
    const tableBody =
      tableContainerRef.current?.getElementsByClassName("ant-table-body")[0];
    if (tableBody) {
      tableBody.addEventListener("scroll", handleScroll);
      return () => tableBody.removeEventListener("scroll", handleScroll);
    }
  }, [handleScroll]);

  const switchTotal = (type) => {
    switch (type) {
      case "":
        return total?.saldo;

      case "Incoming":
        return total?.incomingPaymentsTotal;
      case "Outgoing":
        return total?.vendorPaymentsTotal;

      default:
        break;
    }
  };

  return (
    <LayoutAccountant>
      <div className="p-5 px-10">
        <h1 className="text-2xl font-bold text-basic">
          {t("loaded-payments")}-{code}-{menuName}
        </h1>
        <div className="mt-10 rounded-lg border-t-4 border-t-basic bg-white p-5 drop-shadow-md">
          <span className="text-xl font-bold text-basic">
            {t("filtersPayments")}
          </span>
          <div className="mt-5 grid grid-cols-2 items-end gap-5 sm:grid-cols-3 lg:grid-cols-3">
            <div className="flex flex-col items-start gap-2">
              <span className="font-semibold text-basic">{t("startDate")}</span>
              <Input
                type="date"
                className="w-full"
                value={startDate}
                onChange={(e) => {
                  setData([]);
                  setCurrentPage(0)
                  setStartDate(e.target.value);
                }}
                max={endDate}
              />
            </div>
            <div className="flex flex-col items-start gap-2">
              <span className="font-semibold text-basic">{t("endDate")}</span>
              <Input
                type="date"
                className="w-full"
                value={endDate}
                onChange={(e) => {
                  setData([]);
                  setCurrentPage(0)
                  setEndDate(e.target.value);
                }}
              />
            </div>
            <div className="flex flex-col items-start gap-2">
              <span className="font-semibold text-basic">
                {t("paymentType")}
              </span>
              <Select
                className="w-full"
                value={type}
                onChange={(value) => {
                  setData([]);
                  setType(value);
                  setCurrentPage(0)
                }}
                options={[
                  { value: "", label: t("all") },
                  { value: "Incoming", label: t("Incoming") },
                  { value: "Outgoing", label: t("Outgoing") },
                ]}
              />
            </div>
            <div className="flex flex-col items-start gap-2">
              <span className="font-semibold text-basic">{t("docType")}</span>
              <Select
                className="w-full"
                value={docType}
                onChange={(value) => {
                  setData([]);
                  setDocType(value);
                  setCurrentPage(0)
                }}
                options={[
                  { value: "", label: t("all") },
                  { value: "A", label: t("account") },
                  { value: "C", label: t("customer") },
                  { value: "S", label: t("supplier") },
                ]}
              />
            </div>

            <div className="flex flex-col items-start gap-2">
              <span className="font-semibold text-basic">
                {t("Schot nomi / BP nomi")}
              </span>

              <AutoComplete
                className="w-full"
                value={bpNameSelected}
                options={bpData
                  .filter((item) =>
                    docType !== "" ? item.cardType === docType : item.cardType,
                  )
                  .map((item) => {
                    return {
                      value: item.cardName,
                      label: (
                        <Tooltip
                          title={`${item.cardName}${docType === "A" ? `-${item.cardCode}` : ""}`}
                        >
                          <span>
                            {item.cardName}
                            {docType === "A" ? `-${item.cardCode}` : ""}
                          </span>
                        </Tooltip>
                      ),
                      cardType: item.cardType,
                    };
                  })}
                onChange={(value) => {
                  handleSearchBpName(value);
                  setBpNameSelected(value);
                  if (!value) {
                    setBpName("");
                  }
                }}
                onSelect={(value, label) => {
                  console.log(value, label);
                  setBpNameSelected(value);
                  handleSearchBpName("");
                  setDocType(label.cardType);
                  setBpName(value);
                  setData([]);
                  setCurrentPage(0)
                }}
                showSearch
              />
            </div>
          </div>
        </div>
        <div className="mt-10 rounded-lg border-t-4 border-t-basic bg-white drop-shadow-md">
          <div className="p-5">
            <div className="flex items-center justify-between">
              <span className="text-xl font-bold text-basic">
                {t("loaded-payments")}
              </span>
            </div>
            <div className="mt-5 overflow-x-auto" ref={tableContainerRef}>
              <Table
                columns={columns}
                dataSource={data}
                className="mt-10"
                pagination={false}
                loading={loading || isLoadingMore}
                rowKey={(record) => record.docNum}
                scroll={{
                  y: "calc(100vh - 300px)",
                  scrollToFirstRowOnChange: false,
                }}
                summary={(pageData) => {
                  const totalAmount = switchTotal(type);
                  return (
                    <Table.Summary fixed="bottom">
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0} colSpan={4}>
                          <span className="font-bold text-basic">
                            {t("total")}
                          </span>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={1}>
                          <span className="font-bold text-basic">
                            {new Intl.NumberFormat("fr-FR").format(totalAmount)}{" "}
                            so'm
                          </span>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={2}></Table.Summary.Cell>
                      </Table.Summary.Row>
                    </Table.Summary>
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <ChangeRateModal
        visible={isModalVisible}
        data={data}
        onClose={handleModalClose}
      />
    </LayoutAccountant>
  );
};

export default LoadedPayments;
